const SET_SOCKET_MESSAGE = "SET_SOCKET_MESSAGE"
const defaultState = {
    socketMessage: {},
}

export default function socketReducer(state = defaultState, action) {
    switch (action.type) {
        case SET_SOCKET_MESSAGE:
            return {
                ...state,
                socketMessage: action.payload
            }
        default:
            return state
    }
}

export const setSocketMessageDispatch = (socketMessage) => ({type: SET_SOCKET_MESSAGE, payload: socketMessage})