import React, {useEffect, useState} from 'react';
import './mailList.scss'
import successIcon from "../../../shared/assets/img/success.png";
import errorIcon from "../../../shared/assets/img/error.png";
import eyeIcon from "../../../shared/assets/img/eye.png";
import callOut from "../../../shared/assets/img/call-out.png";
import callIn from "../../../shared/assets/img/call-in.png";
import {
    DataGrid,
} from '@mui/x-data-grid';
import dayjs from "dayjs";
import Navbar from "../../../entitites/navbar/navbar";
import Footer from "../../../entitites/footer/footer";
import {behindAPI} from "../../../app";
import {useMatomo} from "@datapunt/matomo-tracker-react";
import Searchbar from "../../../entitites/searchbar/searchbar";
import {useWindowSize} from "../../../shared/actions/windowSize";
import DOMPurify from 'dompurify';
import NavMenu from "../../../features/sales/navMenu/navMenu";
import SplitPane, { Pane } from 'split-pane-react';
import Loader from "../../../shared/ui/loader";
import {setMessageInReducer} from "../../../app/reducers/chatReducer";
import {ArrowUturnLeftIcon} from "@heroicons/react/24/outline";
import {useDispatch} from "react-redux";

const LogList = (props) => {
    const size = useWindowSize();
    const { trackPageView, trackEvent } = useMatomo()
    const [list, setList] = useState([])
    const [rowData, setRowData] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [filterHeight, setFilterHeight] = useState('calc(100vh - 80px)')
    const [callData, setCallData] = useState({})
    const [emailData, setEmailData] = useState({})
    const dispatch = useDispatch();

    useEffect(() => {
        setIsLoading(true);
        const fetchData = async () => {
            trackPageView({
                documentTitle: 'Logs stats',
            });
            console.log(size);

            // const res = await behindAPI.MailerMessageListGet();
            const res = await behindAPI.SalesLogsGet();
            setIsLoading(false);
            if (res?.success === true) {
                console.log(res?.data);
                setList(res?.data);
                rows(res?.data);
            }
        };

        fetchData();

        const intervalId = setInterval(fetchData, 10000);

        return () => {
            clearInterval(intervalId);
        };
    }, []); // Include dependencies here

    const rows = (data) => {
        const arr = []
        if (size.width > 840) {
            data.map((item,index) => {
                arr.push({
                    id: item.id,
                    col1: [item?.status, item?.type, item?.extra?.opens, item?.direction],
                    col2: item?.time,
                    col3: item?.contact,
                    col5: item?.title,
                })
            })
        } else {
            data.map((item,index) => {
                arr.push({
                    id: index,
                    col1: [item?.queue_time,item?.queue_recipient_mail,item?.queue_message_title],
                    col2: [item?.queue_status,item?.opens],
                })
            })
        }
        setRowData(arr)
        console.log(arr)
        return arr

    }

    const handleRowClick = async (value) => {
        console.log(value);
        if(value.row?.col1[1] === 'call') {
            const res = await behindAPI.SipTranscriptGet(value.id)
            console.log(res)
            if (res?.success === true) {
                setCallData(res?.data)
                setEmailData({})
                setSwitcher(true)
            }
        } else {
            const res = await behindAPI.MailerMessageGet(value.id)
            if (res?.success === true) {
                setEmailData(res?.data)
                setCallData({})
                setSwitcher(true)
            }
        }

    }

    const EmailDetails = ({ emailData }) => {
        // Parse the HTML content
        const parser = new DOMParser();
        const doc = parser.parseFromString(emailData.queue_message_text, 'text/html');

        // Find all <img> tags
        const images = doc.querySelectorAll('img');

        // Remove or modify images from specified URLs
        images.forEach(img => {
            if (img.src.startsWith('https://woololo.com') || img.src.startsWith('https://analytics.woololo.com')) {
                img.remove(); // or modify it as needed
            }
        });

        // Convert the document back to HTML string
        const modifiedHtmlContent = doc.body.innerHTML;

        // Sanitize the modified HTML
        const sanitizedHtml = DOMPurify.sanitize(modifiedHtmlContent);

        return (
            emailData && (
                <div>
                    <p><strong>Title:</strong> {emailData.queue_message_title}</p>
                    <p><strong>Time:</strong> {new Date(emailData.queue_time).toLocaleString()}
                        {emailData.queue_status === 'success' ? <img style={{zoom: '0.7', width: 22, top: 3, left: 5, position: 'relative'}} className='dataGridImage' src={successIcon} /> :
                            <img style={{zoom: '0.7', width: 22, top: 3, left: 5, position: 'relative'}} className='dataGridImage' src={errorIcon} />}</p>
                    <div className="companyCard-border"/>
                    <div style={{paddingTop: 20}}>

                        <div dangerouslySetInnerHTML={{ __html: sanitizedHtml }} />
                    </div>
                </div>
            )
        );
    };
    const [loader, setLoader] = useState(false)
    const [importedData, setImportedData] = useState(true)
    const importData = async () => {
        setLoader(true)
        try {
            const res = await behindAPI.RaetCvMergeCV(callData.guest?.individual_id, callData._id)
            if (res.success === true) {
                setLoader(false)
                setImportedData(false)
            }
        } catch (e) {

        }
    }

    const CallDetails = ({ callData }) => {
        if (!callData || Object.keys(callData).length === 0) {
            return null;
        }

        const formatDate = (timestamp) => {
            return new Date(timestamp).toLocaleString();
        };

        return (
            <div>
                <p><strong>Title:</strong> {callData.chat.title}</p>
                {/*<p><strong>ID:</strong> {callData.id}</p>*/}
                <p><strong>Timestamp:</strong> {formatDate(callData.timestamp)}{callData.direction === 'OUT' ?
                    <img style={{zoom: '0.7', width: 22, top: 3, left: 5, position: 'relative'}} className='dataGridImage' src={callOut} />
                :
                    <img style={{zoom: '0.7', width: 22, top: 3, left: 5, position: 'relative'}} className='dataGridImage' src={callIn} />
                }</p>
                <p>{callData.host.name} ({callData.host.phone_number}) - {callData.guest.name} ({callData.guest.phone_number})</p>
                {importedData && callData.cvUpdate !== undefined &&
                    <div className='logPage__importData__container'>
                        <div className='logPage__importData__text'>
                            We've discovered some relevant information that could enhance your CV. <b>Would you like to import this data now?</b>
                        </div>
                        <div className='form-primaryButton' onClick={()=>importData()} disabled={loader}>{loader?<span><Loader/></span>:<span></span>}
                            Import data
                        </div>
                    </div>
                }
                <div className="companyCard-border"/>
                <div className='companyCard-line'>
                    <label className='form-label'>Chat Overview</label>
                    <p style={{fontSize: 14, lineHeight: '22px'}}>{callData.chat.overview}</p>
                </div>
                <div className="companyCard-border"/>
                <div className='companyCard-line'>
                    <label className='form-label'>Chat Transcript</label>
                    <div  className='logPage__chat__transcript'>
                        {callData.chat.transcript.map((entry, index) => (
                            <div key={index}>
                                <strong>{entry.role === 'host' ? callData.host.name : callData.guest.name}:</strong> {entry.text}
                            </div>
                        ))}
                    </div>

                </div>

            </div>
        );
    };

    const [sizes, setSizes] = useState(['50%', '50%', 'auto']);
    const handleSizeChange = (newSizes) => {

        const newSizesAsStrings = newSizes.map(size => `${size}%`);
        setSizes(newSizesAsStrings);
    };

    const [switcher, setSwitcher] = useState(false)

    return (
        <div className='w-full'>
            {(Object.keys(callData).length > 0 || Object.keys(emailData).length > 0) && switcher ?
                <div className='w-full flex'>
                    <div className='bg-gray-100 rounded-xl p-2 mr-4 cursor-pointer max-h-9' onClick={() => setSwitcher(false)}>
                        <ArrowUturnLeftIcon style={{width: 20}}/>
                    </div>
                    {callData && Object.keys(callData).length > 0 &&
                    <CallDetails callData={callData}/>
                    }
                    {emailData && Object.keys(emailData).length > 0 &&
                    <EmailDetails emailData={emailData}/>
                    }
                </div>
                :
                <div className='w-full'>
                    <div style={{height: filterHeight, marginLeft: 14}}>
                        <DataGrid
                            initialState={{
                                sorting: {
                                    sortModel: [{ field: 'col1', sort: 'desc' }],
                                },
                            }}
                            rowHeight={27}
                            sx = {{
                                border: 0,
                            }}
                            rows = {rowData}
                            onRowClick={handleRowClick}
                            columns={
                                [
                                    { field: 'col1', headerName: 'Status', maxWidth: 100,
                                        sortable: false,
                                        renderCell:
                                            (params) =>
                                                (params.value[0] === 'success' ?
                                                        <div className='data-emailStatus'>
                                                            <img style={{zoom: '0.7'}} className='dataGridImage' src={successIcon} />
                                                            {params.value[1] === 'email' ?
                                                                <>
                                                                    {params.value[2] !== '0'?
                                                                        <div className='data-opened'>
                                                                            <img style={{zoom: '0.7'}} className='dataGridImage' src={eyeIcon} />
                                                                            <span>{params.value[2]}</span>
                                                                        </div>:
                                                                        <div>
                                                                        </div>
                                                                    }
                                                                </>
                                                                :
                                                                <>
                                                                    {params.value[3] ?
                                                                        <>
                                                                            {params.value[3] === 'OUT' ?
                                                                                <>
                                                                                    <img style={{zoom: '0.7', opacity: .4}} className='dataGridImage' src={callOut} />
                                                                                </>
                                                                                :
                                                                                <>
                                                                                    <img style={{zoom: '0.7', opacity: .4}} className='dataGridImage' src={callIn} />
                                                                                </>
                                                                            }
                                                                        </>
                                                                        :
                                                                        <></>
                                                                    }
                                                                </>
                                                            }
                                                        </div> :
                                                        <>
                                                            {params.value[0] === 'pending' ?
                                                                <span style={{display: 'inlineBlock', position: 'relative', bottom: 4, marginLeft: 10}}>
                                                                        <Loader/>
                                                                    </span>
                                                                :
                                                                <span>
                                                                        <img style={{zoom: '0.7'}} className='dataGridImage' src={errorIcon} />
                                                                    </span>
                                                            }
                                                        </>
                                                )
                                    },

                                    { field: 'col2', headerName: 'Date', minWidth: 130,
                                        sortable: false, renderCell: (params) => dayjs(params.value).format('DD MMM YY HH:mm')},
                                    { field: 'col3', headerName: 'Recipient', minWidth: 240,
                                        sortable: false},
                                    // { field: 'col4', headerName: 'Opens', minWidth: 170,
                                    //     sortable: false, renderCell: (params) => params.value ? <DraftsIcon/> : ''},
                                    { field: 'col5', headerName: 'Title', minWidth: 770,
                                        sortable: false},
                                    // { field: 'col6', headerName: 'Website', minWidth: 170,
                                    //     sortable: false},
                                ]
                            }
                            // rowCount={companyCounter}
                            loading={isLoading}
                            //onRowsPerPageChange = {(newPage) => setPageSize(newPage)}
                            rowsPerPageOptions={[25,50,100]}
                            //pagination
                            // page={page}
                            //pageSize = {50}
                            // paginationMode="server"
                            // onPageChange={(newPage) => getNewPage(newPage)}
                            // onPageSizeChange={(newPageSize) => getNewPageSize(newPageSize)}

                        />
                    </div>
                </div>
            }


        </div>
    );
};

export default LogList;