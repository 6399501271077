import React from 'react';
import { useSelector } from "react-redux";
import { RootState } from "../../app/reducers";

const JobDescriptionReport = () => {
    const jdReportDispatcher = useSelector((state: RootState) => state.contact.jd_report);
    const { data } = jdReportDispatcher;

    // Function to render the custom checkbox with appropriate state
    const renderCustomCheckbox = (answer?: boolean) => {
        if (answer === true) {
            return (
                <span className="inline-flex items-center">
                    <span className="w-4 h-4 border border-green-500 bg-green-500 text-white flex items-center justify-center">
                        ✓
                    </span>
                </span>
            );
        } else if (answer === false) {
            return (
                <span className="inline-flex items-center">
                    <span className="w-4 h-4 border border-red-500 text-red-500 flex items-center justify-center">
                        ✕
                    </span>
                </span>
            );
        } else {
            return (
                <span className="inline-flex items-center">
                    <span className="w-4 h-4 border border-gray-500 flex items-center justify-center">
                    </span>
                </span>
            );
        }
    };

    return (
        <div className="max-w-4xl mt-7 mx-auto">
            <h1 className="text-2xl font-bold text-gray-800">{data?.Name}</h1>

            {/*{data?.Role && (*/}
            {/*    <>*/}
            {/*        <h2 className="text-xl text-gray-600 mt-2">Role Overview</h2>*/}
            {/*        <p className="text-gray-700 mt-1">{data.Role}</p>*/}
            {/*    </>*/}
            {/*)}*/}

            {/*{data?.Job && (*/}
            {/*    <>*/}
            {/*        <h2 className="text-xl text-gray-600 mt-4">Job Details</h2>*/}
            {/*        <p className="text-gray-700 mt-1">{data.Job}</p>*/}
            {/*    </>*/}
            {/*)}*/}

            {/*{data?.Culture && (*/}
            {/*    <>*/}
            {/*        <h2 className="text-xl text-gray-600 mt-4">Company Culture</h2>*/}
            {/*        <p className="text-gray-700 mt-1">{data.Culture}</p>*/}
            {/*    </>*/}
            {/*)}*/}

            {data?.MainRequirements?.length > 0 && (
                <>
                    <h2 className="text-xl text-gray-600 mt-4">Main Requirements</h2>
                    <div className="list-disc list-inside mt-1">
                        {data.MainRequirements.map((req: any, index: number) => (
                            <div key={index} className="text-gray-700">
                                {renderCustomCheckbox(req.answer)} <span className="ml-2">{req.data}</span>
                            </div>
                        ))}
                    </div>
                </>
            )}

            {/*{data?.Responsibilities?.length > 0 && (*/}
            {/*    <>*/}
            {/*        <h2 className="text-xl text-gray-600 mt-4">Responsibilities</h2>*/}
            {/*        <div className="list-disc list-inside mt-1">*/}
            {/*            {data.Responsibilities.map((resp: any, index: number) => (*/}
            {/*                <div key={index} className="text-gray-700">*/}
            {/*                    {resp}*/}
            {/*                </div>*/}
            {/*            ))}*/}
            {/*        </div>*/}
            {/*    </>*/}
            {/*)}*/}

            {data?.RequiredHardSkills?.length > 0 && (
                <>
                    <h2 className="text-xl text-gray-600 mt-4">Required Hard Skills</h2>
                    <div className="list-disc list-inside mt-1">
                        {data.RequiredHardSkills.map((skill: any, index: number) => (
                            <div key={index} className="text-gray-700">
                                {renderCustomCheckbox(skill.answer)} <span className="ml-2"><b>{skill.data}</b> - ({skill.comment})</span>
                            </div>
                        ))}
                    </div>
                </>
            )}

            {data?.Frameworks?.length > 0 && (
                <>
                    <h2 className="text-xl text-gray-600 mt-4">Frameworks</h2>
                    <div className="list-disc list-inside mt-1">
                        {data.Frameworks.map((framework: any, index: number) => (
                            <div key={index} className="text-gray-700">
                                {renderCustomCheckbox(framework.answer)} <span className="ml-2"><b>{framework.data}</b> - ({framework.comment})</span>
                            </div>
                        ))}
                    </div>
                </>
            )}

            {data?.OperatingSystems?.length > 0 && (
                <>
                    <h2 className="text-xl text-gray-600 mt-4">Operating Systems</h2>
                    <div className="list-disc list-inside mt-1">
                        {data.OperatingSystems.map((os: any, index: number) => (
                            <div key={index} className="text-gray-700">
                                {renderCustomCheckbox(os.answer)} <span className="ml-2"><b>{os.data}</b> - {os.question}</span>
                            </div>
                        ))}
                    </div>
                </>
            )}

            {data?.NaturalLanguages?.length > 0 && (
                <>
                    <h2 className="text-xl text-gray-600 mt-4">Natural Languages</h2>
                    <div className="list-disc list-inside mt-1">
                        {data.NaturalLanguages.map((language: any, index: number) => (
                            <div key={index} className="text-gray-700">
                                {renderCustomCheckbox(language.answer)} <span className="ml-2"><b>{language.data}</b> - ({language.comment})</span>
                            </div>
                        ))}
                    </div>
                </>
            )}

            {data?.SoftSkills?.length > 0 && (
                <>
                    <h2 className="text-xl text-gray-600 mt-4">Soft Skills</h2>
                    <div className="list-disc list-inside mt-1">
                        {data.SoftSkills.map((softSkill: any, index: number) => (
                            <div key={index} className="text-gray-700">
                                {renderCustomCheckbox(softSkill.answer)} <span className="ml-2"><b>{softSkill.data}</b> - {softSkill.question}</span>
                            </div>
                        ))}
                    </div>
                </>
            )}

            {/*{data?.CulturalSkills?.length > 0 && (*/}
            {/*    <>*/}
            {/*        <h2 className="text-xl text-gray-600 mt-4">Cultural Skills</h2>*/}
            {/*        <div className="list-disc list-inside mt-1">*/}
            {/*            {data.CulturalSkills.map((culturalSkill: any, index: number) => (*/}
            {/*                <div key={index} className="text-gray-700">*/}
            {/*                    {renderCustomCheckbox(culturalSkill.answer)} <span className="ml-2">{culturalSkill.question}</span>*/}
            {/*                </div>*/}
            {/*            ))}*/}
            {/*        </div>*/}
            {/*    </>*/}
            {/*)}*/}


        </div>
    );
};

export default JobDescriptionReport;
