import React, {useState, useRef, FC, useEffect} from 'react';
import { DataGrid, GridRowSelectionModel, GridRenderCellParams } from "@mui/x-data-grid";
import noteIcon from "../../../shared/assets/img/sticky-note.png";
import { useDispatch, useSelector } from "react-redux";
import {behindAPI} from "../../../app";
import {RootState} from "../../../app/reducers";
import './contactGrid.scss'
import {useMatomo} from "@datapunt/matomo-tracker-react";
import {setMobileTransitionDispatch} from "../../../app/reducers/appearanceReducer";
import {Box, Modal} from "@mui/material";
import LoginForm from "../../../entitites/loginForm/loginForm";
import {setChatId} from "../../../app/reducers/chatReducer";
import {useNavigate} from "react-router-dom";
import {useParams} from "react-router";
import {
    contactUploadStatusDispatcher,
    currentContactDispatcher,
    setContactIds,
    setCurrentContactId
} from "../../../app/reducers/currentContact";
import StringAvatar from "../../../shared/lib/StringAvatar";

type Company = {
    _id: string;
    name: string;
    categories: Array<{ name: string }>;
    phones: Array<{ number: string }>;
    description: string;
    description_ext: string;
    contacts: { homepage: string };
};

interface ContactGridProps {

}

const ContactGrid: FC<ContactGridProps> = () => {
    const dispatch = useDispatch()
    const { trackPageView, trackEvent } = useMatomo()
    const createGroup = useSelector((state: RootState) => state.appearance.createGroup)
    const editGroup = useSelector((state: RootState) => state.appearance.editGroup)
    const editGrid = useSelector((state: RootState) => state.appearance.editGrid)
    const isAuth = useSelector((state: RootState) => state.user.isAuth)
    const searchValue = useSelector((state: RootState) => state.company.filter_keyword)
    const filterData = useSelector((state: RootState) => state.company.filter)
    const groupID = useSelector((state: RootState) => state.company.group_id?._id)
    const user_role = useSelector((state: RootState) => state.user.userRole)
    const company_ids = useSelector((state: RootState) => state.company.company_ids)
    const route = useSelector((state: RootState) => state.app.currentApp)
    const uploadStatus = useSelector((state: RootState) => state.contact.uploadStatus);
    const [isLoading, setIsLoading] = useState(false)
    const [page, setPage] = useState(0)
    const [companyCounter, setCompanyCounter] = useState(0)
    const [paginationModel, setPaginationModel] = useState({
        page: 0,
        pageSize: 50,
    });
    const [rowData, setRowData] = useState<Company[]>([])
    const currentCompany = useRef<Company | null>(null)
    const companiesArr = useRef<Company[]>([])
    const routerCompanyId = useParams()
    const [rowSelectionModel, setRowSelectionModel] = useState<GridRowSelectionModel>([]);
    const [guestLimit, setGuestLimit] = useState(false)


    useEffect(() => {
        (async () => {
            if (groupID === 'all' || groupID === 'create') {
                await contactData(searchValue, filterData ,paginationModel.page, paginationModel.pageSize)
            } else {
                await groupData(groupID, paginationModel.page, paginationModel.pageSize)
            }
            dispatch(setContactIds([]));
            setRowSelectionModel([])

        })();

        return () => {
            // this now gets called when the component unmounts
        };
    }, [groupID, editGroup, createGroup, user_role]);


    useEffect(() => {
        (async () => {
            await contactData(searchValue, filterData, 0, 50)

        })();

        return () => {
            // this now gets called when the component unmounts
        };
    }, [searchValue, filterData, user_role]);

    useEffect(() => {
        (async () => {
            (uploadStatus === 'success' || uploadStatus === 'deleted') && await contactData(searchValue, filterData, 0, 50)

        })();

        return () => {
            // this now gets called when the component unmounts
        };
    }, [uploadStatus]);



    async function groupData(name:string, page:number, pageSize:number) {
        setIsLoading(true)
        // @ts-ignore
        const companies = await behindAPI.SalesGroupGet(name, page, pageSize)
        companiesArr.current = companies?.data
        setCompanyCounter(parseInt(companies.count))
        //console.log(companies)
        rows(companiesArr.current)
        setIsLoading(false)
    }
    async function contactData(search:string, filter:any, page:number, pageSize:number) {
        setIsLoading(true)
        let companies
        if (user_role === 'guest') {
            //change it when unauthorized method implemented
            setGuestLimit(true); return
            // companies = await behindAPI.SalesCompaniesGetUnauthorized()
        } else if (user_role === 'user') {
            // @ts-ignore
            companies = await behindAPI.RaetIndividualsGet(search, page, pageSize)
            if (companies.success === true) {
                dispatch(contactUploadStatusDispatcher('updated'));
            }
        }

        companiesArr.current = companies?.data
        setCompanyCounter(parseInt(companies.count))
        //console.log(companies)
        rows(companiesArr.current)
        setIsLoading(false)
    }

    const rows = (data:any) => {
        const arr = []
        let categories
        let arrDate
        console.log(data)
        //for (let key=Object.keys(data).length-1; key>-1; key--)
        for (let key = 0; key < Object.keys(data).length; key++) {
            // if (data?.[key]?.categories.length > 0) {
            //     categories = Object.values(data?.[key]?.categories)
            //     // @ts-ignore
            //     categories = categories.map(item => [item.name]).join(', ')
            //
            // }
            arr.push(
                {
                    id: data?.[key]?._id,
                    //col1 : dayjs(data?.[key]?.date).format('DD MMM YY dd'),
                    col1: [data?.[key]?.name, data?.[key]?.description],
                    // col2: data?.[key]?.description,
                    // col3: data?.[key]?.addresses?.[0]?.region,
                    // // col4: data?.[key]?.notes?.length > 0,
                    // col5: data?.[key]?.phones?.[0]?.number,
                }
            )
        }
        // console.log(typeof(arr))
        // console.log(arr)
        // @ts-ignore
        setRowData(arr)
        return arr

    }

    const navigate = useNavigate();

    const transformCompanyName = (name:any) => {
        return name
            .replace(/&/g, 'and')
            .replace(/[åä]/g, 'a')
            .replace(/ö/g, 'o')
            .replace(/é/g, 'e')
            .replace(/[.,]/g, '-')
            .replace(/[^\w-]|_/g, '-')
            .replace(/-{2,}/g, '-'); // Replace two or more consecutive '-' with a single '-'
    };

    const navigateAndDispatch = (companyId:string) => {
        if (route === 'sales' || route === 'sales-uae') {
            console.log(route)
            navigate("/companies/" + companyId + "-" +transformCompanyName(currentCompany?.current?.name));
        }
        dispatch(setChatId(companyId));
    }

    const handleRowClick = async (params: GridRenderCellParams) => {
        trackEvent({ category: 'companies-list', action: 'company-row-click' })
        // @ts-ignore
        currentCompany.current = companiesArr.current.find(item => item._id === params.row.id);
        // @ts-ignore
        const res = await behindAPI.RaetCvGet(currentCompany.current?._id)
        if (res.success === true) {
            dispatch(currentContactDispatcher(res.data))
            dispatch(setCurrentContactId(currentCompany.current?._id))
            navigateAndDispatch(currentCompany.current?._id !== undefined ? currentCompany.current?._id : '')
        }
        dispatch(setMobileTransitionDispatch(1))

    };


    async function getNewPage(newPage:number, pageSize:number) {
        if (user_role === 'guest' && newPage > 0) {setGuestLimit(true); return}
        setPage(newPage)
        if (groupID === 'all' || groupID === '') {
            await contactData(searchValue,filterData, newPage, pageSize)
        } else {
            await groupData(groupID, paginationModel.page, paginationModel.pageSize)
        }

    }

    useEffect(() => {
        (async () => {
            await getNewPage(paginationModel.page, paginationModel.pageSize)
        })();

        return () => {
            // this now gets called when the component unmounts
        };
    }, [paginationModel]);

    useEffect(() => {
        if (company_ids.length < 1) {
            setRowSelectionModel(company_ids)
        }
    }, [company_ids]);


    // @ts-ignore
    return (
        <div style={{ height: '100%', display: "flex" , width: '100%'}}>
            <DataGrid
                keepNonExistentRowsSelected
                onRowSelectionModelChange={(newRowSelectionModel) => {
                    dispatch(setContactIds(newRowSelectionModel));
                    setRowSelectionModel(newRowSelectionModel);
                }}
                rowSelectionModel={rowSelectionModel}
                //checkboxSelection={createGroup || editGroup}
                checkboxSelection={editGrid}
                //disableRowSelectionOnClick
                initialState={{
                    sorting: {
                        sortModel: [{ field: 'col2', sort: 'desc' }],
                    },
                }}
                rowHeight={47}
                sx={{
                    border: 0,
                }}
                rows={rowData}
                // @ts-ignore
                onRowClick={handleRowClick}
                columns={[
                    // { field: 'col1', headerName: 'Name', minWidth: 270, sortable: false, renderCell: (params) => params.value[1] ? <span><img style={{ zoom: '0.8', marginRight: '5px' }} className='dataGridImage' src={noteIcon} />{params.value[0]}</span> : <span><div style={{ display: 'inline-block', marginRight: '23px' }} />{params.value[0]}</span> },
                    { field: 'col1', headerName: 'Contact', flex: 1, minWidth: 300, sortable: false, renderCell: (params) => params.value[0] === null ? <><i>Noname (please edit contact)</i></> : <>
                            <StringAvatar name={params.value[0]}/>
                            <div className={'pl-4'}>
                                <div className="text-base font-medium text-gray-900">{params.value[0]}</div>
                                <div className="text-sm text-gray-500">{params.value[1]}</div>
                            </div>
                    </> },
                       // { field: 'col2', headerName: 'Description', minWidth: 370, sortable: false },
                    // { field: 'col3', headerName: 'Region', minWidth: 170, sortable: false },
                ]}
                rowCount={companyCounter}
                loading={isLoading}
                pageSizeOptions={[25, 50, 100]}
                pagination
                page={page}
                paginationMode="server"
                paginationModel={paginationModel}
                onPaginationModelChange={setPaginationModel}
            />
            <div>
                <Modal
                    open={guestLimit}
                    //onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    className="howTo-wrapper"
                >
                    <div style={{paddingTop: '20%'}}>
                        <LoginForm/>
                    </div>

                </Modal>
            </div>
        </div>
    );
};

export default ContactGrid;
