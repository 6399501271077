import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { behindAPI } from "../../../app";
import {useDispatch, useSelector} from "react-redux";
import { RootState } from "../../../app/reducers";
import './searchPage.scss';
import SplitPane, { Pane } from 'split-pane-react';
import {setCurrentCompanyId} from "../../../app/reducers/currentCompany";
import CompanyCard from "../../../entitites/sales/companyCard/companyCard";
import NavMenu from "../../../features/sales/navMenu/navMenu";
import {useWindowSize} from "../../../shared/actions/windowSize";
import SearchCard from "../../../features/sales/SearchCard/SearchCard";

interface SalesCompany {
    name: string;
    description: string;
    categories: Category[];
    notes?: string[];
    _id: string;
}

interface Category {
    name: string;
}

interface Company {
    _id: string;
    name: string;
    addresses: { municipality: string | null }[];
    categories: { name: string; code: string }[];
    contacts: any;
}

const SearchPage = (props:any) => {
    const size = useWindowSize();
    const dispatch = useDispatch()
    const [data, setData] = useState<Record<string, SalesCompany>>({});
    const currentCountry = useSelector((state: RootState) => state.company.currentCountry);
    const navigate = useNavigate();
    const location = useLocation();
    const [queryParam, setQueryParam] = useState('');
    const company = useSelector((state: RootState) => state.company.currentCompanyId)
    const [search, setSearch] = useState('')
    const [companyCard, setCompanyCard] =  useState<Record<string, Company>>({});
    
    async function getParams() {
        const queryParams = queryString.parse(location.search);
        const qParam = queryParams.q || '';
        const companyParam = queryParams.company || '';
        setQueryParam(qParam.toString());
        dispatch(setCurrentCompanyId(companyParam.toString()))
        return qParam.toString()
    }

    useEffect(() => {
        (async () => {
            const param = await getParams();
            setSearch(param)
            const res = await behindAPI.SalesCompaniesGetUnauthorized20(param, currentCountry);
            // console.log(res)
            if (res.success === true && typeof res.data === 'object') {
                setData({ ...data, ...res.data });
                dispatch(setCurrentCompanyId(res.data[0]?._id))
                await getCompanyRecord(res.data[0]?._id)
            }
        })();
    }, []);



    useEffect(() => {
        if (queryParam || company) {
            const newQueryString = queryString.stringify({ ...queryString.parse(location.search), q: queryParam, company });
            navigate(`${location.pathname}?${newQueryString}`);
        }
    }, [queryParam, company]);

    const [sizes, setSizes] = useState(['50%', '50%', 'auto']);
    const handleSizeChange = (newSizes: number[]) => {

        const newSizesAsStrings = newSizes.map(size => `${size}%`);
        setSizes(newSizesAsStrings);
    };

    const onSearch = async () => {
        const res = await behindAPI.SalesCompaniesGetUnauthorized20(search, currentCountry);
        // console.log(res)
        if (res.success === true && typeof res.data === 'object') {
            setData({ ...data, ...res.data });
            setQueryParam(search)
        }
    }

    const getCompanyRecord = async (id:string) => {
        let data = {}
        const res = await behindAPI.SalesCatalogueGetRecord(id);
        if (res.success === true) {
            data = { ...data, ...res.data };
            setCompanyCard(data)
        }
    }

    const GridElement = (props: {  item: SalesCompany }) => {
        const dispatch = useDispatch()
        const [selected, setSelected] = useState(false)
        const company = useSelector((state: RootState) => state.company.currentCompanyId)
        const size = useWindowSize();

        useEffect(() => {
            if (company === props.item._id) {
                setSelected(true)
            } else {
                setSelected(false)
            }
        }, [company]);

        const onElementClick = async () => {
            dispatch(setCurrentCompanyId(props.item._id))
            await getCompanyRecord(props.item._id)
            setSelected(true)
        }

        return (
            <div
                className={`ku__grid__element ku__grid__search--element ${selected ? 'ku__grid__element--selected' : ''}`}
                onClick={onElementClick}
                style={size.width && size.width < 801 ? { backgroundColor: 'white' } : {}}
            >
                {/*<img style={{ maxWidth: '100%' }} src={companyLink} />*/}
                {size.width && size.width < 801 && selected ?
                    <></>
                    :
                    <>
                        <span className="ku__grid__search__header">{props.item.name}</span>&nbsp;
                        <span className="ku__grid__search__category">        {props.item.categories.map(category => category.name).join(', ')}
    </span>
                    </>
                }


                {size.width && size.width < 801 && selected && <SearchCard data={companyCard}/>}
            </div>

        );
    }


    return (
        <div>
            {size.width && size.width > 800 ?
                <div className='kub__container'>
                    <div className='kub__blocks'>
                        {Object.entries(data).map(([key, item]) => (
                            <div className='kub__block kub__block--3grid'
                                style={{minHeight: 200, overflow: 'hidden'}}
                            >
                                <div className='kub__card__header'>
                                    <span className='card-icon-companies'/> {item?.name}
                                </div>

                                <div className='kub__card__category'>
                                    {item?.categories.map((category: { name: any; }) => category.name).join(', ')}
                                </div>
                                <div className='kub__card__description'>
                                    {item?.description && item.description.length > 100 ? `${item.description.slice(0, 100)}...` : item.description}
                                </div>

                            </div>
                        ))}
                    </div>

                </div>
            // <SplitPane {...props as any}
            //            split='vertical'
            //            sizes={sizes}
            //            onChange={handleSizeChange}
            // >
            //     <Pane minSize={480} maxSize='70%'>
            //         <div className='promptsPage__left__nav'>
            //             <div style={{display: 'flex', alignItems: 'center', width: '100%'}}>
            //                 <NavMenu/>
            //                 <div className='ku__search__input'>
            //                     <input type='text' value={search} onChange={(e) => setSearch(e.target.value)} />
            //                     <button onClick={onSearch} className='form-primaryButton'>Search</button>
            //                 </div>
            //             </div>
            //         </div>
            //         <div className='ku__grid'>
            //             <div className='ku__grid__col'>
            //                 {Object.entries(data).map(([key, item]) => (
            //                     <div className='ku__grid__row ku__grid__search--row' key={key}>
            //                         <GridElement item={item}/>
            //                     </div>
            //                 ))}
            //             </div>
            //         </div>
            //     </Pane>
            //     <div style={{borderLeft: '1px solid rgba(0,0,0,.1)', height: '100vh', paddingTop: '3px', overflowY: 'auto'}}>
            //         <SearchCard data={companyCard}/>
            //         {/*<pre>{JSON.stringify(companyCard, null, 2)}</pre>*/}
            //     </div>
            //
            // </SplitPane>
                :
                <div>
                    <div className='promptsPage__left__nav'>
                        <div style={{display: 'flex', alignItems: 'center', width: '100%'}}>
                            <NavMenu/>
                            <div className='ku__search__input'>
                                <input type='text' value={search} onChange={(e) => setSearch(e.target.value)} />
                                <button onClick={onSearch} className='form-primaryButton'>Search</button>
                            </div>
                        </div>
                    </div>
                    <div className='ku__grid' style={{height: "auto"}}>
                        <div className='ku__grid__col'>
                            {Object.entries(data).map(([key, item]) => (
                                <div className='ku__grid__row ku__grid__search--row' key={key}>
                                    <GridElement item={item}/>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            }
        </div>
    );
};

export default SearchPage;