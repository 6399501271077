import React, { useEffect, useRef, useState } from 'react';
import './sip.scss';
import {behindAPI, ua} from "../../app"; // Assuming 'ua' is correctly imported
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../app/reducers";
import { setCallStatusDispatch, setSipContactDispatch } from "../../app/reducers/sipReducer";
import {useWindowSize} from "../../shared/actions/windowSize";
import SipLiveTranscript from "../../features/sip/sipLiveTranscript";
import {io} from "socket.io-client";
import {ChevronDownIcon, XMarkIcon} from "@heroicons/react/24/outline";
import {PhoneIcon, PhoneXMarkIcon} from "@heroicons/react/24/solid";
import OpenInFullOutlinedIcon from "@mui/icons-material/OpenInFullOutlined";
const IS_DEV = process.env.DEV;

interface SipWidgetProps {
    // Define props if any
}

const SipWidget: React.FC<SipWidgetProps> = () => {
    const [show, setShow] = useState(false);
    const [timer, setTimer] = useState(0);
    const intervalRef = useRef<NodeJS.Timeout | null>(null);
    const [callSession, setCallSession] = useState<any>(null);
    const [extended, setExtended] = useState(0);
    const dispatch = useDispatch();
    const sipContact = useSelector((state: RootState) => state.sip.contact);
    const callStatus = useSelector((state: RootState) => state.sip.callStatus);

    useEffect(() => {
        if((sipContact?.phone && sipContact?.phone !== '') || (sipContact?.name && sipContact?.name !== '')) {
            setShow(true);
            setExtended(0);
        }
        return () => {
            //@ts-ignore
            setMessageHistory([])
        };
    }, [sipContact]);

    const onHangUp = () => {
        if (callSession) {
            callSession.terminate(); // Assuming terminate is the correct method
            dispatch(setSipContactDispatch({}));
            setShow(false);
            dispatch(setCallStatusDispatch('end'));
        }
    };

    const onSipCall = async (phone: string) => {

        if (sipContact?.meta) {
            try {
                let res;
                if (sipContact.meta.project_id) {
                    res = await behindAPI.SipCallCreateIntent(phoneValue, sipContact.meta);
                }
                if (sipContact.meta.cv_id) {
                    res = await behindAPI.SipCallCreateIntent(phoneValue, sipContact.meta);
                }
                console.log('API call successful:', res);
            } catch (error) {
                console.error('Error during API call:', error);

            }
        }

        const handleAudioStream = (audioStream: any) => {
            const PATH_ARRAY = Array.from({ length: 30 }, (_, index) => `M ${index*9.5},255 l 0,-0`);
            // Create an AudioContext to manage audio operations
            const audioContext = new AudioContext();

            // Create an AnalyserNode to extract frequency data from the audio stream
            const analyser = audioContext.createAnalyser();

            // Create a source node from the audio stream
            const source = audioContext.createMediaStreamSource(audioStream);

            // Connect the source to the analyser
            source.connect(analyser);

            // Set the FFT (Fast Fourier Transform) size for frequency analysis
            analyser.fftSize = 1024;

            // Create a Uint8Array to store frequency data
            const frequencyArray = new Uint8Array(analyser.frequencyBinCount);

            // Function to continuously update the visual representation of audio frequencies
            const updateVisualization = () => {
                // Find the container in the HTML document where the SVG visualization will be rendered
                const container = document.querySelector('#mask');

                if(container!=null){
                    container.innerHTML = '';
                }

                // Clear the container for new visualizations

                // Request animation frame for smooth updates
                requestAnimationFrame(updateVisualization);

                // Get frequency data into the frequencyArray
                analyser.getByteFrequencyData(frequencyArray);

                // Map the PATH_ARRAY with frequency data to create SVG path elements
                PATH_ARRAY.forEach((path, index) => {
                    // Calculate the new length of the SVG path based on frequency data
                    const newLength = Math.floor(frequencyArray[index]) - (Math.floor(frequencyArray[index]) % 2);

                    // Create an SVG path element
                    const element = document.createElementNS('http://www.w3.org/2000/svg', 'path');

                    // Set attributes for the SVG path
                    let start = 255-((255-(newLength)/2)/2)
                    element.setAttribute('d', `M ${index*9.5},${start} l 0,-${newLength / 2}`);

                    // Append the SVG path to the container
                    if(container!=null){
                        container.appendChild(element);
                    }

                });
            };

            // Start the continuous visualization
            updateVisualization();
        };

        //navigator.getUserMedia({ audio: true, video: false }, handleAudioStream, ()=>{});

        let eventHandlers = {
            'progress': function (e: any) {
                dispatch(setCallStatusDispatch('progress'));
                console.log('call is in progress');
            },
            'failed': function (e: any) {
                console.log('call failed with cause: ', e);
            },
            'ended': function (e: any) {
                console.log('call ended with cause: ', e);
                dispatch(setCallStatusDispatch('end'));
            },
            'confirmed': function (e: any) {
                setTimer(0)
                dispatch(setCallStatusDispatch('confirmed'));
                console.log('call confirmed');
            },
        };

        const callOptions = {
            eventHandlers: eventHandlers,
            mediaConstraints: { audio: true, video: false },
            pcConfig: {
                        'iceServers': [
                            {
                                urls: 'turn:sip.behind.ai:3478',
                                username: 'test',
                                credential: 'test'
                            }
                        ]
                    }
        };

        const session = ua.call(`sip:${phone}@${process.env.REACT_APP_SIP_SERVER}`, callOptions);
        if (session) {
            session.connection.addEventListener('addstream', (e:any) => {
                var audio = document.createElement('audio');
                audio.srcObject = e.stream;
                handleAudioStream(e.stream)
                audio.play();
                setCallSession(session);
            });
        }
    };

    useEffect(() => {
        if (callStatus === 'confirmed') {
            intervalRef.current = setInterval(() => {
                setTimer(prev => prev + 1);
            }, 1000);
        } else {
            intervalRef.current && clearInterval(intervalRef.current);
        }

        return () => {
            intervalRef.current && clearInterval(intervalRef.current);
        };
    }, [callStatus]);

    const formatTime = (totalSeconds: number) => {
        const minutes = Math.floor(totalSeconds / 60);
        const seconds = totalSeconds % 60;
        return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    };
    const size = useWindowSize()
    // Draggable functionality
    const [isDragging, setIsDragging] = useState(false);
    const [position, setPosition] = useState({ x: size.width ? size.width/2-175 : 0, y: 20 });
    const positionRef = useRef({ x: size.width ? size.width/2-175 : 0, y: 20 });

    const handleMouseMove = (e: MouseEvent) => {
        if (!isDragging) return;
        const dx = e.clientX - positionRef.current.x;
        const dy = e.clientY - positionRef.current.y;
        setPosition(prev => ({ x: prev.x + dx, y: prev.y + dy }));
        positionRef.current = { x: e.clientX, y: e.clientY };
    };

    const handleMouseUp = () => {
        setIsDragging(false);
    };

    const handleMouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
        setIsDragging(true);
        positionRef.current = { x: e.clientX, y: e.clientY };
    };

    useEffect(() => {
        if (isDragging) {
            document.addEventListener('mousemove', handleMouseMove);
            document.addEventListener('mouseup', handleMouseUp);
        } else {
            document.removeEventListener('mousemove', handleMouseMove);
            document.removeEventListener('mouseup', handleMouseUp);
        }

        return () => {
            document.removeEventListener('mousemove', handleMouseMove);
            document.removeEventListener('mouseup', handleMouseUp);
        };
    }, [isDragging]);

    const token = localStorage.getItem('access_token');
    const [messageHistory, setMessageHistory] = useState([]);
    const [socket, setSocket] = useState(null);

    useEffect(() => {
        if (!socket) {
            const newSocket = io(`${process.env.REACT_APP_MESSAGE_WEBSOCKET}`, {
                autoConnect: false,
                transports: ['websocket', 'polling', 'flashsocket'],
                reconnection: true,
                reconnectionDelay: 1000,
                reconnectionDelayMax: 5000,
                reconnectionAttempts: 99999,
            });

            newSocket.auth = { token };
            newSocket.connect();

            newSocket.on("connect", () => {
                console.log('Connected to transcript: ', newSocket.id);
            });

            newSocket.on('message', (message: string) => {
                //@ts-ignore
                if (message.message.type === 'sip.call.transcript') {
                    if (newSocket.id) {
                        //@ts-ignore
                        setMessageHistory(prevMessageHistory => [
                            ...prevMessageHistory,
                            {
                                socket: newSocket.id,
                                //@ts-ignore
                                message: message.message.content,
                            },
                        ]);
                    }
                }

            });
//@ts-ignore
            setSocket(newSocket);
        }

        // Cleanup function to disconnect socket on unmount
        return () => {
            if (socket) {
                //@ts-ignore
                socket.disconnect();
            }
        };
    }, [socket, token]);

    const [phoneValue, setPhoneValue] = useState<number | ''>(sipContact?.phone || '');

    useEffect(() => {

        setPhoneValue(sipContact?.phone || ((process.env.NODE_ENV =='development')?'+46100000803':'') );
    }, [sipContact]);

    const handlePhoneChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        // @ts-ignore
        setPhoneValue(value);
    };

    return (
        <>
            <audio autoPlay/>
            {show && (
                <>
                    {extended === 1 ? <>
                        <div className={'fixed top-0 left-0 w-full h-full overflow-hidden z-40 p-5'}>
                            <div style={{
                                backgroundColor:'#1b1533'
                            }}
                            className={'sipWidget__box__extended w-full rounded-lg flex max-h-full h-full min-h-full overflow-hidden items-center box-border'}

                            >
                                <div className='my-16 flex flex-col'>

                                    <div className='text-white flex justify-center mb-1 text-xl px-16 text-center'>
                                        {sipContact?.name}
                                    </div>
                                    <div className='text-white/40 flex justify-center mb-5 text-sm px-16'>
                                        {sipContact?.phone}
                                    </div>
                                    <div className='flex justify-center mb-5 text-sm px-16'>
                                        <svg preserveAspectRatio="none" version="1.1" viewBox="0 0 255 255" className='sipWidget__audioVisualisation'>
                                            <defs>
                                                <mask id="mask" />
                                                <linearGradient id="gradient" x1="0%" y1="0%" x2="0%" y2="100%">
                                                    <stop stop-color="#fff" stopOpacity="1" />
                                                </linearGradient>
                                            </defs>
                                            <rect x="0" y="0" width="100%" height="100%" fill="url(#gradient)" mask="url(#mask)"></rect>
                                        </svg>
                                    </div>
                                    <div className='flex justify-center'>

                                        <div onClick={()=> setExtended(0)} className='sipWidget__extend'>
                                            <ChevronDownIcon style={{width: 16, color: 'white', display: "inline-block"}}/>
                                        </div>
                                        <div className='sipWidget__timer text-white' onClick={onHangUp}>

                                            <div>{formatTime(timer)}</div><div className='sipWidget__hangUp' />

                                        </div>
                                    </div>


                                </div>
                                <div style={{width: `80%`, height: '100%'}} className={'flex flex-col'}>
                                    <div className='flex justify-end items-center'>
                                        <div className='bg-white/10 hover:bg-white/20 rounded-xl p-2 cursor-pointer max-w-9 max-h-9 mt-2 mr-2'
                                             onClick={()=> setExtended(extended === 1 ? 0 : 1)}
                                        >
                                            <OpenInFullOutlinedIcon style={{width: 20, color: 'white', position: 'relative', bottom: 3}}/>
                                        </div>
                                    </div>
                                    <SipLiveTranscript messageHistory={messageHistory}/>
                                </div>
                            </div>

                        </div>
                        </>
                    :
                    <>

                        <div
                            className={`sipWidget__box  ${extended === 1 ? 'flex flex-row w-1/2 items-center' : extended === 2  ? 'flex flex-row w-11/12 h-5/6 items-center' : 'flex-col w-80'} rounded-lg`}
                            onMouseDown={handleMouseDown}
                            style={{ left: position.x, top: position.y, position: 'absolute' }}
                        >

                            <div style={{ display: 'flex', justifyContent: 'center', width: `${extended === 0 ? '100%' : extended === 1 ? '50' : '20%'}`, height: '100%', alignItems: `${extended === 1 ? 'flex-start' : 'center'}`}}>

                                {callStatus !== 'confirmed' ? (
                                    <div className='flex flex-col w-full'>
                                        <div className='flex justify-end'>
                                            <div className='bg-white/10 hover:bg-white/20 rounded-xl p-2 cursor-pointer max-w-9 max-h-9 mt-2 mr-2' onClick={() => { dispatch(setSipContactDispatch({})); setShow(false); }}>
                                                <XMarkIcon style={{width: 20, color: 'white'}}/>
                                            </div>
                                        </div>
                                        {/*<div className='text-white'>*/}
                                        {/*    {JSON.stringify(sipContact)}*/}
                                        {/*</div>*/}

                                        <div className='text-white flex justify-center mb-5 text-xl px-16 text-center'>
                                            {sipContact?.name}
                                        </div>

                                        <div className='text-white flex justify-center mb-7'>
                                            <input
                                                type="text"
                                                value={phoneValue}
                                                onChange={handlePhoneChange}
                                                placeholder="Enter phone number"
                                                className='p-2 rounded-xl text-center text-white bg-white/10'
                                            />
                                        </div>
                                        <div className='px-16 pb-16 flex justify-center'>
                                            {/*@ts-ignore*/}
                                            <div onClick={() => onSipCall(phoneValue)} className='w-12 h-12 flex items-center justify-center rounded-full bg-green-500 hover:bg-green-600 cursor-pointer'>
                                                <PhoneIcon style={{width: 20, color: 'white'}}/>
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    <div className='my-16 flex flex-col'>

                                        <div className='text-white flex justify-center mb-1 text-xl px-16 text-center'>
                                            {sipContact?.name}
                                        </div>
                                        <div className='text-white/40 flex justify-center mb-5 text-sm px-16'>
                                            {sipContact?.phone}
                                        </div>
                                        <div className='flex justify-center mb-5 text-sm px-16'>
                                            <svg preserveAspectRatio="none" version="1.1" viewBox="0 0 255 255" className='sipWidget__audioVisualisation'>
                                                <defs>
                                                    <mask id="mask" />
                                                    <linearGradient id="gradient" x1="0%" y1="0%" x2="0%" y2="100%">
                                                        <stop stop-color="#fff" stopOpacity="1" />
                                                    </linearGradient>
                                                </defs>
                                                <rect x="0" y="0" width="100%" height="100%" fill="url(#gradient)" mask="url(#mask)"></rect>
                                            </svg>
                                        </div>
                                        <div className='flex justify-center'>

                                            <div onClick={()=> setExtended(extended === 0 ? 1 : 0)} className='sipWidget__extend'>
                                                <ChevronDownIcon style={{width: 16, color: 'white', display: "inline-block"}}/>
                                            </div>
                                            <div className='sipWidget__timer text-white' onClick={onHangUp}>

                                                <div>{formatTime(timer)}</div><div className='sipWidget__hangUp' />

                                            </div>
                                        </div>


                                    </div>


                                )}
                            </div>
                        </div>

                    </>
                    }
                </>

            )}
        </>
    );
};

export default SipWidget;
