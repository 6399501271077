// src/hooks/useKanban.js
import { useState, useCallback } from 'react';
import { behindAPI } from "../app"; // Adjust this path to where your API utilities are located

const initialBoard = {
    id: '', // or null if you want to set it as null
    title: 'My Project',
    columns: [
        {
            id: 'column1',
            name: '',
            cards: [
                {
                    id: '',
                    content: '',
                    title: '',
                    data: {
                        type: '',
                        id: '',
                    }
                },
            ]
        },
    ],
    createdTimestamp: '' // or null if you want to set it as null
};

const useKanban = () => {
    const [kanbanBoard, setKanbanBoard] = useState(initialBoard)

    const updateKanbanBoard = useCallback(async (board:any) => {
        try {
            const res = await behindAPI.KanbanBoardUpdate(board?.id, board);
            if (res.success) {
                setKanbanBoard(board);
                return true;
            }
        } catch (e) {
            console.error(e);
            return false;
        }
    }, []);

    const createKanbanCard = useCallback(async (board_id:string, card:any) => {
        try {
            const res = await behindAPI.KanbanBoardAddCard(board_id, card);
            if (res.success) {

                return true;
            }
        } catch (e) {
            console.error(e);
            return false;
        }
    }, []);

    const getKanbanBoards = useCallback(async () => {
        try {
            const res = await behindAPI.KanbanBoardsGet();
            if (res.success) {
                return res.data[0]?.id; // or return the whole list if needed
            }
        } catch (e) {
            console.error(e);
            return 'None';
        }
    }, []);

    const createKanbanBoard = useCallback(async () => {
        try {
            const res = await behindAPI.KanbanBoardCreate();
            if (res.success) {
                setKanbanBoard(res.data);
                return true;
            }
        } catch (e) {
            console.error(e);
            return false;
        }
    }, []);

    const getKanbanBoard = useCallback(async (id:string) => {
        try {
            const res = await behindAPI.KanbanBoardGet(id);
            if (res.success) {
                setKanbanBoard(res.data);
                return true;
            }
        } catch (e) {
            console.error(e);
            return false;
        }
    }, []);


    return { kanbanBoard, setKanbanBoard, updateKanbanBoard, createKanbanCard, getKanbanBoards, createKanbanBoard, getKanbanBoard };
};

export default useKanban;