import React, {useEffect, useRef, useState} from 'react';
import { useDispatch, useSelector } from "react-redux";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import PersonCard from "../../entitites/raet/personCard";
import EditPersonCard from "../../entitites/sales/editPersonCard/editPersonCard";
import FileUploader from "../../features/fileUploader/fileUploader";
import Searchbar from "../../entitites/searchbar/searchbar";
import ContactGrid from "../../entitites/raet/contactGrid/contactGrid";
import { RootState } from "../../app/reducers";
import { setMobileTransitionDispatch } from "../../app/reducers/appearanceReducer";
import {behindAPI} from "../../app";
import {
    contactUploadStatusDispatcher,
    currentContactDispatcher,
    setCurrentContactId
} from "../../app/reducers/currentContact";
import {Box, Modal} from "@mui/material";
import NavMenu from "../../features/sales/navMenu/navMenu";
import {useWindowSize} from "../../shared/actions/windowSize";
import SplitPane, { Pane } from 'split-pane-react';
import ContactTable from "../../entitites/raet/contactGrid/contactTable";
import {PlusIcon, XMarkIcon} from "@heroicons/react/24/outline";

// @ts-ignore
const ContactsCardNotes = (props) => {
    const dispatch = useDispatch();
    const contact = useSelector((state: RootState) => state.contact.contact);
    const mobTransition = useSelector((state: RootState) => state.appearance.mobileRightTransition);
    const uploadStatus = useSelector((state: RootState) => state.contact.uploadStatus);
    const [jsonData, setJsonData] = useState('');
    const [createForm, setCreateForm] = useState(false);
    const [newEntry, setNewEntry] = useState(false);
    const [editData, setEditData] = useState(false);
    const [prevContactId, setPrevContactId] = useState(null);

    useEffect(() => {
        if (prevContactId !== contact._id) {
            setJsonData(contact);
            setPrevContactId(contact._id);  // Update the previous contact._id with the current one
            setNewEntry(false);
            setEditData(false);
            setCreateForm(false);
        }
    }, [contact, prevContactId]);

    useEffect(() => {
        (async () => {
           if (uploadStatus === 'deleted') {
                setJsonData('');
                setPrevContactId(null);  // Update the previous contact._id with the current one
                setNewEntry(false);
                setEditData(false);
                setCreateForm(false);
            }

        })();

        return () => {
            // this now gets called when the component unmounts
        };
    }, [uploadStatus]);



    const delay = (ms: number): Promise<void> => new Promise(res => setTimeout(res, ms));

    const onBackPathButton = async () => {
        await delay(100);
        dispatch(setMobileTransitionDispatch(2));
        await delay(700);
        dispatch(setMobileTransitionDispatch(0));
    };

    const onEdit = (value: boolean) => {
        setEditData(value);
    };

    const onBack = (value: boolean) => {
        dispatch(currentContactDispatcher([]));
        dispatch(setCurrentContactId(''));
    };

    const onSubmit = async (data: any) => {
        const res = await behindAPI.RaetCvUpdate(data._id,data)
        if (res.success === true) {
            console.log(res)
            dispatch(contactUploadStatusDispatcher('success'));
            setJsonData(data)
            setEditData(false)
        }
        console.log(data);
    };

    const size = useWindowSize();

    const [sizes, setSizes] = useState(['30%', '50%', 'auto']);
    const handleSizeChange = (newSizes:any) => {
        // @ts-ignore
        const newSizesAsStrings = newSizes.map(size => `${size}%`);
        setSizes(newSizesAsStrings);
    };

    const selectedProject = useSelector((state: RootState) => state.raet.projectSelection);

    return (
            <div className='min-w-full rounded-3xl' style={{background: "white"}}>
                {/*// @ts-ignore*/}
                    {newEntry ?
                        (createForm ?
                                <EditPersonCard data={editData ? jsonData : ''} onSubmit={onSubmit} onCancel={(value) => setCreateForm(value)} />
                                :
                                <div className='w-full'>
                                    <div className='flex w-full justify-end pt-5'>
                                        <div className='bg-gray-100 hover:bg-gray-200 rounded-xl p-2 mr-6 cursor-pointer max-h-9 ' onClick={() => setNewEntry(false)}>
                                            <XMarkIcon style={{width: 20}}/>
                                        </div>
                                    </div>
                                    <div className={' px-8'}>
                                        <FileUploader type={'cv'} object={selectedProject}/>
                                    </div>

                                    {/*<div>or</div>*/}
                                    {/*<div>*/}
                                    {/*    <button className='form-button' onClick={() => setCreateForm(true)}>Create manually</button>*/}
                                    {/*</div>*/}
                                </div>
                        )
                        :
                        (editData ?
                                <EditPersonCard data={jsonData} onSubmit={onSubmit} onCancel={(value) => (setCreateForm(value), onEdit(value))} />
                                :
                                <>
                                    {Object.keys(jsonData).length !== 0 ? <PersonCard onEdit={onEdit} data={jsonData} onBack={onBack}/> :
                                        <div style={{width: '100%', minWidth: '100%'}}
                                        >
                                            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                                                <div style={{display: "flex", justifyContent: "flex-end", paddingTop: 20, paddingBottom: 10, width: '100%'}}>
                                                    {!newEntry ?
                                                        <div className='bg-gray-100 hover:bg-gray-200 rounded-xl p-2 cursor-pointer mr-6 max-h-9' onClick={() => setNewEntry(true)}>
                                                                 <PlusIcon style={{width: 20}}/>
                                                        </div>
                                                        :
                                                        <></>
                                                    }
                                                </div>
                                            </div>
                                            <div className='behind-dataGrid' style={{ height: 'calc(100vh - 40px)', paddingLeft: 14, paddingRight: 14 }}>
                                                {/*<ContactGrid/>*/}
                                                <ContactTable/>

                                            </div>
                                        </div>
                                    }
                                </>

                        )
                    }
                <div/>
            </div>

    );
};

export default ContactsCardNotes;