const SET_INSTANCES_DATA = "SET_INSTANCES_DATA"
const SET_INSTANCE_DATA = "SET_INSTANCE_DATA"
const SET_MAIN_TABLE_MODE = "SET_MAIN_TABLE_MODE"
const SET_SELECTED_GROUP = "SET_SELECTED_GROUP"


const defaultState = {
    instancesData: null,
    instanceData: null,
    mainTableMode: 'overview',
    selectedGroup: '',
}

export default function mastogramReducer(state = defaultState, action) {
    switch (action.type) {
        case SET_INSTANCES_DATA:
            return {
                ...state,
                instancesData: action.payload,
            }
        case SET_INSTANCE_DATA:
            return {
                ...state,
                instanceData: action.payload,
            }
        case SET_MAIN_TABLE_MODE:
            return {
                ...state,
                mainTableMode: action.payload,
            }
        case SET_SELECTED_GROUP:
            return {
                ...state,
                selectedGroup: action.payload,
            }
        default:
            return state
    }
}

export const setMastogramInstancesData = instancesData => ({type: SET_INSTANCES_DATA, payload: instancesData})
export const setMastogramInstanceData = instanceData => ({type: SET_INSTANCE_DATA, payload: instanceData})
export const setMastogramMainTableMode = mode => ({type: SET_MAIN_TABLE_MODE, payload: mode})
export const setMastogramSelectedGroup = selectedGroup => ({type: SET_SELECTED_GROUP, payload: selectedGroup})
