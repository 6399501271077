import React, {useEffect, useRef, useState} from 'react';
import './companyCard.scss'
import mailIcon from "../../../shared/assets/img/mail.png";
import sendIcon from "../../../shared/assets/img/icon-send-w.png";
import companyIcon from "../../../shared/assets/img/company.png";
import phoneIcon from "../../../shared/assets/img/phone.png";
import homeIcon from "../../../shared/assets/img/home.png";
import fbIcon from "../../../shared/assets/img/fb-icon.png";
import youtubeIcon from "../../../shared/assets/img/youtubeIcon.png";
import linkedinIcon from "../../../shared/assets/img/linkedinIcon.png";
import instagramIcon from "../../../shared/assets/img/instagramIcon.png";
import vimeoIcon from "../../../shared/assets/img/vimeoIcon.png";
import LinearProgressWithLabel from '@mui/material/LinearProgress';
import linkIcon from "../../../shared/assets/img/link.png";
import parse from "html-react-parser";
import Notes from "../../../pages/sales/notes/notes";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from '../../../app/reducers'
import { setSendingWindowDispatch } from "../../../app/reducers/appearanceReducer";
import {Menu, MenuItem, Modal} from "@mui/material";
import Fade from "@mui/material/Fade";
import {useNavigate} from "react-router-dom";
import {behindAPI} from "../../../app";
import {setChatId} from "../../../app/reducers/chatReducer";
import {useWindowSize} from "../../../shared/actions/windowSize";
import {setSearchFormReducer} from "../../../app/reducers/userSearch";
import ProductsAndServices from "../../../features/sales/companyCard/productsAndServices/productsAndServices";
import LoginForm from "../../loginForm/loginForm";
import SecondLine from "../../../features/sales/companyCard/secondLine/secondLine";
import RegistryData from "../../../features/sales/companyCard/registryData/registryData";
import Loader from "../../../shared/ui/loader";
import {currentCompanyDispatcher, setCurrentCompanyId} from "../../../app/reducers/currentCompany";
import {useParams} from "react-router";
import {setSipContactDispatch} from "../../../app/reducers/sipReducer";
import phoneIconWhite from "../../../shared/assets/img/phone.png";

const CompanyCard = () => {
    const dispatch = useDispatch()
    const company = useSelector((state: RootState) => state.company.company)
    const size = useWindowSize();
    const [companyLink, setCompanyLink] = useState('')
    const user_role = useSelector((state: RootState) => state.user.userRole)
    const currentCompanyId = useSelector((state: RootState) => state.company.currentCompanyId)
    const [guestLimit, setGuestLimit] = useState(false)
    const [loader, setLoader] = useState<boolean>(false)
    const [progressClicked, setProgressClicked] = useState<boolean>(false)
    const [progress, setProgress] = useState<any>({value: 0, buffer: 0})
    const prevBuffer = useRef(0)
    const [shouldPoll, setShouldPoll] = useState(true);
    const [scrapingStatus, setScrapingStatus] = useState<any>()
    const [companyData, setCompanyData] = useState<any>()
    const route = useSelector((state: RootState) => state.app.currentApp)
    const routerCompanyId = useParams()
    const useClearInterval = useRef<any>()

    function onSendButton() {
        if (user_role === 'guest') {setGuestLimit(true); return}
        dispatch(setSendingWindowDispatch(true))
    }
    function onSendMessageButton() {
        dispatch(setSendingWindowDispatch(true))
    }

    useEffect(() => {
        if (shouldPoll) {
            useClearInterval.current = setInterval(async () => {
                await getScrapingStatus();
                await getCompanyNewData();
            }, 3000); // every 3 seconds

            // Clean up interval on unmount
            return () => clearInterval(useClearInterval.current);
        }
    }, [shouldPoll]);

    useEffect(() => {
        (async () => {
            console.log(company)
            if (company?.contacts?.homepage) {
                await getCompanyFavicon(company.contacts?.homepage)
            }

            setContacts([])

        })();

        return () => {
            // this now gets called when the component unmounts
        };
    }, [company]);

    const delay = (ms : number) => new Promise(
        resolve => setTimeout(resolve, ms)
    );

    // useEffect(() => {
    //     (async () => {
    //         await getScrapingStatus()
    //
    //     })();
    //
    //     return () => {
    //         // this now gets called when the component unmounts
    //     };
    // }, [company]);


    useEffect(() => {
        (async () => {
            setCompanyData({...companyData, ...company})
            prevBuffer.current=0
            await getScrapingStatus()
            await getCompanyNewData();
        })();

        return () => {
            // this now gets called when the component unmounts
            clearInterval(useClearInterval.current)
        };
    }, [currentCompanyId]);


    async function getCompanyFavicon(value: string) {
        setCompanyLink('')
        const link = `https://t3.gstatic.com/faviconV2?client=SOCIAL&type=FAVICON&fallback_opts=TYPE,SIZE,URL&url=${value}&size=256`
        setCompanyLink(link)
    }

    const [contacts, setContacts] = useState([])

    const getContacts = async () => {
        if (contacts.length < 1) {
            // @ts-ignore
            const res = await behindAPI.SalesCompanyContactsGet(currentCompanyId)
            if (res.success === true) {
                setContacts(res.data)
            }
        }
    };

    const getCompanyNewData = async () => {
        const res = await behindAPI.SalesCompanyGet(currentCompanyId)
        if (res.success === true) {
            setCompanyData({...companyData, ...res.data})
            dispatch(currentCompanyDispatcher(res.data))
        }
    }

    const getProgress = (all:any, scraped:any, parsed: any) => {
        prevBuffer.current = progress.value
        let value = ((parseInt(scraped)+parseInt(parsed))/(parseInt(all)*2)*100)-8
        if (value > 100) {
            value = 90
        }
        if (value === Infinity) {
            value = 10
        }
        setProgress({
            value: value < 0 || isNaN(value) ? 0 : value,
            // buffer: prevBuffer.current,
            buffer: 0,
        })
        console.log(value < 0 || isNaN(value) ? 0 : value)
        console.log(prevBuffer.current)
        prevBuffer.current = (parseInt(scraped)+parseInt(parsed))/(parseInt(all)*2)*100

    }

    const getScrapingStatus = async () => {
        // @ts-ignore
        const res = await behindAPI.SalesCompanyGetProgressStatus(currentCompanyId)
        console.log(res.data)
        if (res.success === true) {
            setScrapingStatus(res)

            if (res.data === "NOT_CACHED") { setLoader(false) }
            if (res.data === "NOT_CACHED" || res.data === "NO_WEBSITE" || res.data === "DONE") {
                setShouldPoll(false);
            } else {
                setShouldPoll(true);
                getProgress(res.state?.pages_queued, res.state?.pages_scraped, res.state?.pages_parsed)
            }
            return(res.data)
        }
    };

    const onRequestMoreData = async () => {
        if (companyData?.contacts?.homepage !== undefined) {
            setLoader(true)
            // @ts-ignore
            const res = await behindAPI.SalesCompanyParseSite(company?._id, 'f13d0aae-b1bb-44c8-b3e4-21dca9742bba')
            if (res.success === true) {
                const setStatus = await behindAPI.WorkflowQueueSetStatus('f13d0aae-b1bb-44c8-b3e4-21dca9742bba', 'pending')
                if (setStatus.success === true) {
                    await getScrapingStatus()
                    setProgress({value: 0, buffer: 0})
                    prevBuffer.current = 0
                    setShouldPoll(true)
                }
            }
        }
    };

    const navigate = useNavigate();
    const createChat = async (member:string) => {
        // @ts-ignore
        const res = await behindAPI.ChatChatCreate(company?.name, member, '',  company?._id)
        if (res.success === true) {
            dispatch(setChatId(res.data.chat_id))
            navigate("/chat/" + res.data.chat_id);
            dispatch(setSearchFormReducer(false))
            handleClose()
        }
    }

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    //@ts-ignore
    const handleClick = async (event) => {
        if (user_role === 'guest') {setGuestLimit(true); return}
        setAnchorEl(event.currentTarget);
        await getContacts()
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const ContactsDropdown = () => {

        return <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            TransitionComponent={Fade}
            MenuListProps={{
                'aria-labelledby': 'basic-button',
            }}
        >
            {contacts.map((list) => (
                <div className='chatWindow-left-menu-element'><MenuItem onClick={() => createChat(list)}>
                    {list}</MenuItem></div>
            ))}
        </Menu>
    }
    const getIcon = (label: string) => {
        switch(label) {
            case 'facebook.com':
                return fbIcon;
            case 'linkedin.com':
                return linkedinIcon;
            case 'instagram.com':
                return instagramIcon;
            case 'youtube.com':
                return youtubeIcon;
            case 'vimeo.com':
                return vimeoIcon;
            default:
                return linkIcon;
        }
    };

    return (
        <div style={{ marginRight: '1%' }}>
            {companyData && <div className="companyCard">

                <div className="companyCard-header" style={size.width && size.width < 840 ? {flexDirection: 'column'} : {}}>
                    <div className="companyCard-name">
                        {companyLink && <div style={{ display: 'inline-block', width: '14px', marginRight: '7px', position: 'relative', top: '0px' }}><img style={{ maxWidth: '100%' }} src={companyLink} /> </div>}
                        <span>{companyData.name}</span>
                    </div>
                    {companyData.hasEmail ?
                        <button className='form-primaryButton' onClick={handleClick}>Start chat<img src={sendIcon} style={{ width: '14px', opacity: '1', marginLeft: '7px', marginRight: '0px' }} /></button> : <div></div>
                    }
                        <ContactsDropdown/>
                </div>
                <div className="companyCard-category">
                    <img className='companyCard-icon' src={companyIcon} /> {[...(companyData.categories?.map((category: { name: string; }) => category.name) || []), ...(companyData.homepage?.companyIndustries || [])].join(', ')}
                </div>
                <SecondLine/>
                {companyData?.contacts && <div>
                    <div className="companyCard-border"/>
                    <div className="companyCard-phone companyCard-line" style={{display:'flex', alignItems: 'center'}}>
                        {companyData.phones?.length > 0 &&
                        <div>
                            {companyData.phones?.map((phone: {number: string, text: string}, index: number) => (
                                <div key={index} onClick={() => dispatch(setSipContactDispatch({phone: phone.number, name: companyData.name}))}>
                                    <img className='companyCard-icon' src={phoneIconWhite} alt="Phone" />
                                    {phone.number}
                                </div>
                            ))}
                        </div>
                        }
                        {/*<div style={{marginLeft: '10px'}}>*/}
                        {/*    {companyData.hasEmail ?*/}
                        {/*        <button className='form-button' onClick={onSendButton}>Send email<img src={mailIcon} style={{ width: '14px', opacity: '.8', marginLeft: '7px', marginRight: '0px' }} /></button> : <div></div>}*/}

                        {/*</div>*/}
                    </div>
                    <div className="companyCard-border"/>
                    <div className="companyCard-links companyCard-line">
                        {companyData.contacts?.homepage && <>
                        <img className='companyCard-icon' src={homeIcon} style={{ position: 'relative', zoom: '1.1', top: '2px' }} />
                        <a href={companyData.contacts?.homepage} target="_blank" >{companyData.contacts?.homepage?.replace(/^http:\/\/|https:\/\/|www./gm, '')}</a></>}
                        {/*{companyData.contacts?.facebook && <> &nbsp;|&nbsp;*/}
                        {/*<img className='companyCard-icon' src={fbIcon} style={{ position: 'relative', zoom: '.9', top: '2px' }} />*/}
                        {/*<a href={companyData.contacts?.facebook} target="_blank" >{companyData.contacts?.facebook?.replace(/^http:\/\/|https:\/\/www.facebook.com/gm, 'fb')}</a></>*/}
                        {/*}*/}
                        {companyData.contacts?.social?.length>0 &&
                        <>&nbsp;|&nbsp;

                            {
                                // @ts-ignore
                                companyData.contacts?.social?.map((item, index) => (
                                    <a style={{display: 'inline-block', top: '2px', position: 'relative'}} key={index} href={item.url} target="_blank" rel="noopener noreferrer">
                                        <img className='companyCard-icon' src={getIcon(item.label)}/>
                                    </a>
                                ))}
                        </>
                        }
                    </div>
                    {companyData?.contacts?.links?.length > 0 &&
                    <div className="companyCard-line">
                         <img className='companyCard-icon' src={linkIcon} />
                        {
                            companyData?.contacts && companyData?.contacts?.links &&
                            // parse(companyData.contacts.links.map((link: { url: string; }) => '<a href=' + link.url + ' target="_blank" >' + link.url?.replace(/^http:\/\/|https:\/\/|www./gm, '') + '</a>').join(' | '))
                            // @ts-ignore
                            parse(companyData?.contacts?.links?.map((link: { url: string; }) => '<a href=' + link.url + ' target="_blank" >' + link.label?.replace(/^http:\/\/|https:\/\/|www./gm, '') + '</a>').join(' | '))
                        }
                    </div>}
                </div>}
                {companyData.contacts?.homepage && scrapingStatus?.data  === "NOT_CACHED" &&
                    <div>
                        <button onClick={()=>onRequestMoreData()} className="form-button" disabled={loader}>{loader?<span><Loader/></span>:<span></span>}Request&nbsp;more&nbsp;data</button>
                    </div>
                }

                {(scrapingStatus?.data === "DONE" || scrapingStatus?.data  === "NO_WEBSITE") && <div className="companyCard-parsingBorder"/>}

                {scrapingStatus?.data  !== 'DONE' &&  scrapingStatus?.data  !== 'NO_WEBSITE' && scrapingStatus?.data  !== 'ERR' && scrapingStatus?.data  !== 'NOT_CACHED' &&
                <>
                    <div className='companyCard-parsingProgress'>
                        <LinearProgressWithLabel variant="buffer" valueBuffer={progress.buffer} value={progress.value} />

                    </div>
                        <div style={{marginTop: '-7px'}}>
                        <label className='companyCard-parsingProgressLabel' onClick={() => setProgressClicked(!progressClicked)}>{progress.value.toFixed(0)}%</label>
                    {progressClicked &&
                        <label className='companyCard--parsingProgressLabel-legend'>
                        &nbsp;
                    {scrapingStatus?.data === 'SCRAPPING' && 'Scraping data '}
                    {scrapingStatus?.data  === 'IN_PROGRESS' && 'Parsing data '}
                    {(scrapingStatus?.data  === 'IN_PROGRESS' || scrapingStatus?.data  === 'SCRAPPING')
                        && <>(scraped&nbsp;{scrapingStatus?.state?.pages_scraped}/in&nbsp;queue&nbsp;{scrapingStatus?.state?.pages_queued}/parsed&nbsp;{scrapingStatus?.state?.pages_parsed}) </>}
                    {scrapingStatus?.data  === 'PENDING' && 'Pending...'}
                    {(scrapingStatus?.data  === 'RENDERING') && 'Analyzing data...'}
                    {/*{JSON.stringify(scrapingStatus)}*/}
                        </label>}
                        </div>
                </>
                }


                <div className="companyCard-description">
                    <p>
                        {company?.description}
                    </p>
                </div>
                <ProductsAndServices/>
                <RegistryData/>
            </div>}
            {companyData && <Notes company_id={companyData._id} company_notes={companyData.notes} />}
            <div>
                <Modal
                    open={guestLimit}
                    //onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    className="howTo-wrapper"
                >
                    <div style={{paddingTop: '20%'}}>
                        <LoginForm/>
                    </div>

                </Modal>
            </div>
        </div>
    );
};

export default CompanyCard;