import React, { useEffect, useState } from 'react';
import './sipLiveTranscript.scss';
import { io } from "socket.io-client";
import {setJobDescriptionMode} from "../../app/reducers/raet/raetReducer";
import {SparklesIcon, ArrowUpRightIcon} from "@heroicons/react/24/outline";
import {setSipContactDispatch} from "../../app/reducers/sipReducer";
import OpenInFullOutlinedIcon from '@mui/icons-material/OpenInFullOutlined';
import {behindAPI} from "../../app";
import {currentContactDispatcher, setCurrentContactId} from "../../app/reducers/currentContact";
import {setMobileTransitionDispatch} from "../../app/reducers/appearanceReducer";
import {useSelector} from "react-redux";
import {RootState} from "../../app/reducers";
import Loader from "../../shared/ui/loader";

// @ts-ignore
const SipLiveTranscript = ({messageHistory}) => {
    const [isLoading, setIsLoading] = useState(false);
    const [isAI, setIsAI] = useState(false);
    const generateAI = async () => {
        setIsLoading(true)
        setTimeout(()=>{
            setIsLoading(false);
            setIsAI(true)
        }, 3000)
    };
    return (
            <div className='sipWidget__transcript__container w-full border-l border-white/10 text-white my-7 px-8 flex flex col'
            style={{height: 'calc(100% - 100px)'}}
            >
            {/*{JSON.stringify(messageHistory)}*/}
            <div className='h-3/6 max-h-3/6 shrink-0 flex overflow-scroll flex-col'
                 style={{minHeight: '50%', maxHeight: '50%' }}
            >
                {/*// @ts-ignore*/}
                {messageHistory?.map((entry, index) => (
                    <div key={index}>
                        {/*//@ts-ignore  */}
                        <strong className={'text-white/30'}>{entry.message.role === 'host' ? 'You' : entry.message.role}:&nbsp;</strong>{entry.message.text}
                    </div>
                ))}
                {/*<div>*/}
                {/*    <strong className={'text-white/30'}>You:&nbsp;</strong>What technologies are you most comfortable working with? Can you discuss a project where you utilized these technologies extensively?*/}
                {/*</div>*/}

            </div>
            <div className='border-t border-white/10 mt-2 pt-2 h-3/6 max-h-3/6 shrink-0 flex overflow-scroll flex-col gray/30'
                 style={{minHeight: '50%', maxHeight: '50%'}}
            >
                <div>
                    {/*<div>*/}
                    {/*    Could you walk us through how you would design and implement a small application that tracks user activities on a website in real time?*/}
                    {/*</div>*/}
                    {/*<div>*/}
                    {/*    Describe a complex problem you’ve faced in your previous projects. How did you approach solving it? What was the outcome?*/}
                    {/*</div>*/}
                    {/*<div>*/}
                    {/*    What technologies are you most comfortable working with? Can you discuss a project where you utilized these technologies extensively?*/}
                    {/*</div>*/}
                    {/*[*/}
                    {/*{"question": "I noticed that your CV doesn't mention Redux. Can you share your experience with state management libraries, specifically Redux?"},*/}
                    {/*{"question": "Have you worked with Next.js in any of your recent projects? If so, could you tell me about your experience with server-side rendering and static site generation?"},*/}
                    {/*{"question": "Do you have any experience with Remix? If not, are you familiar with the key features of this framework and how it compares to others you've used?"},*/}
                    {/*{"question": "Tailwind CSS is important for our front-end styling. Can you let me know if you have experience using utility-first CSS frameworks like Tailwind?"},*/}
                    {/*{"question": "Have you worked with Material-UI (MUI) for building responsive and customizable UI components in your previous projects?"},*/}
                    {/*{"question": "Could you talk about your experience working with standard web technologies like HTML and CSS? Do you feel confident in building complex layouts with them?"}*/}
                    {/*]*/}
                </div>
                {isLoading &&
                    <div style={{display: 'flex', width: '100%', justifyContent: 'center', alignItems: 'center'}}>
                        <div role="status" className={'mb-4'}>
                            <svg aria-hidden="true"
                                 className="inline w-10 h-10 text-gray-200 animate-spin dark:text-gray-600 fill-violet-600"
                                 viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                    fill="currentColor"/>
                                <path
                                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                    fill="currentFill"/>
                            </svg>
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div>
                }
                {isAI &&
                <div>
                    <div className='text-blue-500'>I noticed that your CV doesn't mention Redux. Can you share your experience with state management libraries, specifically Redux?</div>
                    <div className='text-blue-500'>Have you worked with Next.js in any of your recent projects? </div>
                    <div className='text-blue-500'>Do you have any experience with Remix? If yes, are you familiar with the key features of this framework and how it compares to others you've used?</div>
                    <div className='text-blue-500'>Tailwind CSS is important for our front-end styling. Can you let me know if you have experience using utility-first CSS frameworks like Tailwind?</div>
                    <div className='text-blue-500'>Have you worked with Material-UI (MUI) for building responsive and customizable UI components in your previous projects?</div>
                    <div className='text-blue-500'>Could you talk about your experience working with standard web technologies like HTML and CSS? Do you feel confident in building complex layouts with them?</div>
                </div>
                }

                <div className='flex justify-center mt-5 mb-4'>
                    <button className="bg-white/10 text-white rounded-lg px-6 py-1 hover:bg-white/20"
                            onClick={() => generateAI()}
                    >
                        <SparklesIcon style={{width: 16, color: 'white', display: "inline-block"}} className={'mr-2'}/>Get AI suggestions
                    </button>
                </div>

            </div>

        </div>

    );
};

export default SipLiveTranscript;
