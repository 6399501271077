import React, {useEffect, useState} from 'react';
import {setJobDescriptionMode} from "../../app/reducers/raet/raetReducer";
import {ArrowUturnLeftIcon} from "@heroicons/react/24/outline";
import PWButton from "../../shared/ui/btn/PhonewiseButton";
import {useDispatch, useSelector} from "react-redux";
import {
    setMastogramInstancesData,
    setMastogramMainTableMode,
    setMastogramSelectedGroup
} from "../../app/reducers/mastogram/mastogramReducer";
import {RootState} from "../../app/reducers";
import {behindAPI} from "../../app";
import Alert from '@mui/material/Alert';

const MastogramMastodonCreateEdit = () => {
    const dispatch = useDispatch();
    const selectedInstance = useSelector((state: RootState) => state.mastogram.instanceData);
    const mode = useSelector((state: RootState) => state.mastogram.mainTableMode);
    const [instanceData, setInstanceData] = useState({
        instance_uuid: "",
        instance_domain: "",
        mastodon_token: " ",
        instance_enabled: false,
        instance_post: false,
        instance_forward: false,
        instance_fw_reblogs: true,
        instance_fw_bookmarks: false,
        mastodon_account: {
            avatar: "",
            username: "",
            display_name: "",
        },
        instance_post_privacy: "public",
        prompt_id: null
    });

    useEffect(() => {
        if (mode !== "create") {
            setInstanceData({
                ...instanceData,
                instance_uuid: selectedInstance.instance_uuid || "",
                instance_domain: selectedInstance.instance_domain || "",
                mastodon_token: selectedInstance.mastodon_token || "",
                instance_enabled: selectedInstance.instance_enabled || false,
                instance_post: selectedInstance.instance_post || false,
                instance_forward: selectedInstance.instance_forward || false,
                instance_fw_reblogs: selectedInstance.instance_fw_reblogs || true,
                instance_fw_bookmarks: selectedInstance.instance_fw_bookmarks || false,
                instance_post_privacy: selectedInstance.instance_post_privacy || "public",
                mastodon_account: {
                    avatar: selectedInstance?.mastodon_account?.avatar || "",
                    username: selectedInstance?.mastodon_account?.username || "",
                    display_name: selectedInstance?.mastodon_account?.display_name || "",
                },
                prompt_id: selectedInstance.prompt_id || null
            });
        } else {
            setInstanceData({
                ...instanceData,
                instance_uuid: "",
                instance_domain: "",
                mastodon_token: "",
                instance_enabled: false,
                instance_post: false,
                instance_forward: false,
                instance_fw_reblogs: true,
                instance_fw_bookmarks: false,
                instance_post_privacy: "public",
                prompt_id: null
            });
        }
    }, [selectedInstance]);

    const updateInstanceData = async (id:string) => {
        const res = await behindAPI.MastogramMastodonUpdate(id, instanceData?.instance_domain, instanceData.mastodon_token,
            instanceData.instance_enabled, instanceData.instance_post, instanceData.instance_forward,
            instanceData.instance_fw_reblogs, instanceData.instance_fw_bookmarks, instanceData.instance_post_privacy, instanceData.prompt_id);
        if (res.success) {
            dispatch(setMastogramMainTableMode('overview'));
            const res = await behindAPI.MastogramMastodonGet();
            dispatch(setMastogramInstancesData(res?.data))
        }
    }

    const onMastogramInstanceSave = async () => {
        if(selectedInstance?.instance_uuid !== '' && selectedInstance?.instance_uuid !== undefined){
            await updateInstanceData(instanceData?.instance_uuid)
        } else {
            const create = await behindAPI.MastogramMastodonCreate(instanceData.instance_domain, instanceData.mastodon_token);
            if (create.success) {
                await updateInstanceData(create?.data)
            }
        }
    }
    return (
        <div className="max-w-full mx-auto p-4">
            {/*{JSON.stringify(instanceData)}*/}
            <div className='flex items-center justify-between mb-6'>
                <div className='flex items-center'>
                    <div className='bg-gray-100 hover:bg-gray-200 rounded-xl p-2 mr-4 cursor-pointer max-w-9 max-h-9'
                         onClick={() => dispatch(setMastogramMainTableMode('overview'))}
                    >
                        <ArrowUturnLeftIcon style={{width: 20}}/>
                    </div>
                    <div className='text-xl font-semibold'>
                        Edit channel/instance
                    </div>
                </div>
                <div>
                    <PWButton onClick={() => onMastogramInstanceSave()}>
                        Save
                    </PWButton>
                </div>
            </div>
            {instanceData?.mastodon_account?.display_name !== undefined && instanceData?.mastodon_account?.display_name != '' ?
                (
                    <div className="flex items-center mb-4">
                        {/*@ts-ignore*/}
                        <img src={`${instanceData?.mastodon_account?.avatar ? instanceData?.mastodon_account?.avatar : "https://via.placeholder.com/50"}`}
                             alt="Profile"
                             className="w-12 h-12 rounded-xl"
                        />
                        <div className="ml-4">
                            <p className="font-medium text-gray-700">{instanceData?.mastodon_account?.display_name}</p>
                            <p className="text-sm text-gray-500">@{instanceData?.mastodon_account?.username}</p>
                        </div>
                    </div>
                )
                :
                (
                    <div>
                        <div>
                            <Alert severity="info">Watch the <a href="https://youtu.be/z-uB-s4Jj8g">instruction video</a> first</Alert>
                            <br/>
                            <Alert severity="info">1) Create a development token with proper scopes</Alert>
                        </div>
                    </div>
                )

            }
            <div className="flex items-center mb-4">
                <label
                    htmlFor="instanceEnabled"
                    className="mr-4 font-medium text-gray-700"
                >
                    Instance enabled
                </label>
                <label className="inline-flex items-center cursor-pointer">
                    <input
                        type="checkbox"
                        checked={instanceData.instance_enabled}
                        onChange={() => setInstanceData({
                            ...instanceData,
                            instance_enabled: !instanceData.instance_enabled // Toggle instance_enabled on change
                        })}
                        className="sr-only peer"
                    />
                    <div
                        className="relative w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-violet-300 dark:peer-focus:ring-violet-800 dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-violet-600"
                    ></div>
                </label>
            </div>

            <div className="mb-4">
                <label htmlFor="domain" className="block font-medium text-gray-700">
                    Domain
                </label>
                <input
                    type="text"
                    id="domain"
                    value={instanceData.instance_domain}
                    onChange={(e) => setInstanceData({ ...instanceData, instance_domain: e.target.value })}
                    className="mt-1 w-full p-2 border border-gray-300 rounded-md bg-gray-200"
                />
            </div>

            <div className="mb-4">
                <label htmlFor="token" className="block font-medium text-gray-700">
                    Token
                </label>
                <input
                    type="text"
                    id="token"
                    value={instanceData.mastodon_token}
                    onChange={(e) => setInstanceData({ ...instanceData, mastodon_token: e.target.value })}
                    className="mt-1 w-full p-2 border border-gray-300 rounded-md bg-gray-200"
                />
            </div>

            <div className="mb-4">
                <h3 className="font-semibold text-gray-700">Bridge options</h3>
                <div className="mt-2">
                    <label className="inline-flex items-center">
                        <input
                            type="checkbox"
                            checked={instanceData.instance_post}
                            onChange={() => setInstanceData({
                                ...instanceData,
                                instance_post: !instanceData.instance_post
                            })}
                            className="form-checkbox h-5 w-5 text-violet-600 bg-violet-500"
                        />
                        <span className="ml-2 text-gray-700">(Write)Post to this instance</span>
                    </label>
                </div>
                <div className="mt-2">
                    <label className="inline-flex items-center">
                        <input
                            type="checkbox"
                            checked={instanceData.instance_forward}
                            onChange={() => setInstanceData({
                                ...instanceData,
                                instance_forward: !instanceData.instance_forward
                            })}
                            className="form-checkbox h-5 w-5 text-violet-600"
                        />
                        <span className="ml-2 text-gray-700">(Read)Forward from this instance</span>
                    </label>
                </div>
                <div className="mt-2">
                    <label className="inline-flex items-center">
                        <input
                            type="checkbox"
                            checked={instanceData.instance_fw_reblogs}
                            onChange={() => setInstanceData({
                                ...instanceData,
                                instance_fw_reblogs: !instanceData.instance_fw_reblogs
                            })}
                            className="form-checkbox h-5 w-5 text-violet-600"
                        />
                        <span className="ml-2 text-gray-700">Forward reposts</span>
                    </label>
                </div>
                <div className="mt-2">
                    <label className="inline-flex items-center">
                        <input
                            type="checkbox"
                            checked={instanceData.instance_fw_bookmarks}
                            onChange={() => setInstanceData({
                                ...instanceData,
                                instance_fw_bookmarks: !instanceData.instance_fw_bookmarks
                            })}
                            className="form-checkbox h-5 w-5 text-violet-600"
                        />
                        <span className="ml-2 text-gray-700">Forward bookmarks</span>
                    </label>
                </div>
            </div>

            <div className="mb-4">
                <label htmlFor="privacy" className="block font-medium text-gray-700">
                    New posts privacy
                </label>
                <select
                    id="privacy"
                    value={instanceData.instance_post_privacy}
                    onChange={(e) => setInstanceData({
                        ...instanceData,
                        instance_post_privacy: e.target.value
                    })}
                    className="mt-1 w-full p-2 border border-gray-300 rounded-md"
                >
                    <option value="public">Public</option>
                    <option value="private">Private</option>
                    <option value="protected">Protected</option>
                </select>
            </div>
            {mode!=='create' &&
                <div className={'text-black/40 text-sm'}>
                    <i>Instance ID: {instanceData.instance_uuid}</i>
                </div>
            }

        </div>
    );
};

export default MastogramMastodonCreateEdit;