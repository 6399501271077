import React, { useState } from 'react';
import sendIcon from "../../../shared/assets/img/icon-send-w.png";
import {useWindowSize} from "../../../shared/actions/windowSize";
import {currentContactDispatcher} from "../../../app/reducers/currentContact";
import {useDispatch} from "react-redux";
import Loader from "../../../shared/ui/loader";
import PWButton from "../../../shared/ui/btn/PhonewiseButton";
import {ArrowUturnLeftIcon} from "@heroicons/react/24/outline";

interface EditProps {
    data?: any;
    onSubmit: (data: any) => void;
    onCancel: (data: boolean) => void;
}

const EditPersonCard: React.FC<EditProps> = ({ data = {}, onSubmit, onCancel }) => {
    const defaultData = {
        name: '',
        summary: '',
        outline: {
            detailedSummary: '',
            summaryEducation: '',
            summaryLanguageSkills: '',
            summarySkills: '',
            summaryWorkingExperience: ''
        },
        contacts: {
            emails: [],
            websites: [],
            phones: [],
            addresses: [{
                country: '',
                city: ''
            }],

        },
        languages: [
            {
                language: '',
                level: ''
            }
        ],
        education: [
            {
                degree: '',
                educationName: '',
                institutionName: '',
                startDate: '',
                endDate: '',
                others: [],
                location: {
                    country: '',
                    city: ''
                },
            }
        ],
        jobs: [
            {
                companyName: '',
                position: '',
                description: '',
                startDate: '',
                endDate: '',
                location: {
                    country: '',
                    city: ''
                },
                others: []
            }
        ],
        softSkills: [],
        hardSkills: [],
        interests: [],
        certificates: [
            {
                date: '',
                description: '',
                institution: '',
                name: '',
                others: []
            }
        ],
        others: [
            {
                name: '',
                data: ''
            }
        ]
    };

    const [editableData, setEditableData] = useState({ ...defaultData, ...data });
    const dispatch = useDispatch()

    const handleInputChange = (key: string, value: any) => {
        setEditableData((prevData:any) => ({
            ...prevData,
            [key]: value
        }));
    };

    const addNewField = (category: string, defaultValue: any) => {
        const newCategoryValue = [...(editableData[category] || []), defaultValue];
        handleInputChange(category, newCategoryValue);
    };

    const addNewNestedField = (category: string, subCategory: string, defaultValue: any) => {
        const newSubCategoryValue = [...(editableData[category][subCategory] || []), defaultValue];
        handleInputChange(category, { ...editableData[category], [subCategory]: newSubCategoryValue });
    };

    const deleteField = (category: string, index: number) => {
        const updatedValues = [...editableData[category]];
        updatedValues.splice(index, 1);
        handleInputChange(category, updatedValues);
    };

    const deleteNestedField = (category: string, subCategory: string, index: number) => {
        const updatedValues = [...editableData[category][subCategory]];
        updatedValues.splice(index, 1);
        handleInputChange(category, { ...editableData[category], [subCategory]: updatedValues });
    };

    const addNewCustomField = (category: string) => {
        const defaultCustomField = { name: '', data: '' };
        const newCategoryValue = [...(editableData[category] || []), defaultCustomField];
        handleInputChange(category, newCategoryValue);
    };

    const handleSubmit = () => {

        onSubmit(editableData);
    };
    const size = useWindowSize();
    return (
        <div style={{ marginRight: '1%' }} className={'px-8'}>
            <div className="companyCard__edit">
                <div  className='mainContainer-nav-right'>
                    <div className='bg-gray-100 rounded-xl p-2 mr-4 cursor-pointer' onClick={() => onCancel(false)}>
                        <ArrowUturnLeftIcon style={{width: 20}}/>
                    </div>
                    <PWButton onClick={handleSubmit}>Save</PWButton>
                </div>

                <div className="companyCard-header" style={size.width && size.width < 840 ? {flexDirection: 'column'} : {}}>
                    {/* Name field */}
                    <div className="companyCard-name" style={{width: '100%'}}>
                        <input style={{width: '100%', fontSize: '110%', padding: '10px'}}
                                className="form-input"
                                value={editableData.name}
                                onChange={(e) => handleInputChange('name', e.target.value)}
                                placeholder="Contact name"
                        />
                    </div>
                </div>

                <fieldset className='form--fieldset'>
                    <legend className='form--legend'>Contacts:</legend>

                    {/* Emails */}
                    <div className='form--fieldLine'>
                        <label>Emails:</label>
                        <button className='form-sqButton' onClick={() => addNewNestedField('contacts', 'emails', '')}>+</button>
                    </div>
                    {editableData.contacts?.emails?.map((email: string, index: number) => (
                        <div className='form--fieldInputLine' key={index}>
                            <input className='form-input' style={{flexBasis: '100%'}}
                                placeholder="Email"
                                value={email}
                                onChange={(e) => {
                                    const newEmails = [...editableData.contacts.emails];
                                    newEmails[index] = e.target.value;
                                    handleInputChange('contacts', { ...editableData.contacts, emails: newEmails });
                                }}
                            />
                            <button className='form-sqButton' onClick={() => deleteNestedField('contacts', 'emails', index)}>-</button>
                        </div>
                    ))}

                    {/* Websites */}
                    <div className='form--fieldLine'>
                        <label>Websites:</label>
                        <button className='form-sqButton' onClick={() => addNewNestedField('contacts', 'websites', '')}>+</button>
                    </div>

                    {editableData.contacts?.websites?.map((website: string, index: number) => (
                        <div className='form--fieldInputLine' key={index}>
                            <input className='form-input' style={{flexBasis: '100%'}}
                                placeholder="Website"
                                value={website}
                                onChange={(e) => {
                                    const newWebsites = [...editableData.contacts.websites];
                                    newWebsites[index] = e.target.value;
                                    handleInputChange('contacts', { ...editableData.contacts, websites: newWebsites });
                                }}
                            />
                            <button className='form-sqButton' onClick={() => deleteNestedField('contacts', 'websites', index)}>-</button>
                        </div>
                    ))}

                    {/* Phones */}
                    <div className='form--fieldLine'>
                        <label>Phones:</label>
                        <button className='form-sqButton' onClick={() => addNewNestedField('contacts', 'phones', '')}>+</button>
                    </div>

                    {editableData.contacts?.phones?.map((phone: string, index: number) => (
                        <div className='form--fieldInputLine' key={index}>
                            <input className='form-input' style={{flexBasis: '100%'}}
                                placeholder="Phone"
                                value={phone}
                                onChange={(e) => {
                                    const newPhones = [...editableData.contacts.phones];
                                    newPhones[index] = e.target.value;
                                    handleInputChange('contacts', { ...editableData.contacts, phones: newPhones });
                                }}
                            />
                            <button className='form-sqButton' onClick={() => deleteNestedField('contacts', 'phones', index)}>-</button>
                        </div>
                    ))}

                    {/* Addresses */}
                    <div className='form--fieldLine'>
                        <label>Addresses:</label>
                        <button className='form-sqButton' onClick={() => addNewNestedField('contacts', 'addresses', { country: '', city: '' })}>+</button>

                    </div>
                    {editableData.contacts?.addresses?.map((address: any, index: number) => (
                        <div className='form--fieldInputLine' key={index}>
                            <input className='form-input'
                                placeholder="Country"
                                value={address.country}
                                onChange={(e) => {
                                    const newAddresses = [...editableData.contacts.addresses];
                                    newAddresses[index].country = e.target.value;
                                    handleInputChange('contacts', { ...editableData.contacts, addresses: newAddresses });
                                }}
                            />
                            <input className='form-input'
                                placeholder="City"
                                value={address.city}
                                onChange={(e) => {
                                    const newAddresses = [...editableData.contacts.addresses];
                                    newAddresses[index].city = e.target.value;
                                    handleInputChange('contacts', { ...editableData.contacts, addresses: newAddresses });
                                }}
                            />
                            <button className='form-sqButton' onClick={() => deleteNestedField('contacts', 'addresses', index)}>-</button>
                        </div>
                    ))}
                </fieldset>

                {/* Summary field */}
                <fieldset className='form--fieldset'>
                    <legend className='form--legend'>Summary:</legend>
                    <textarea className='form-textarea'
                        value={editableData.summary}
                        onChange={(e) => handleInputChange('summary', e.target.value)}
                        placeholder="Enter summary"
                    />
                </fieldset>
                {/* Outline */}
                <fieldset className='form--fieldset'>
                    <legend className='form--legend'>Outline:</legend>
                    <div className='form--fieldLine'>
                        <label>Detailed summary:</label>
                    </div>
                    <div className='form--fieldLine'>
                        <textarea className='form-textarea'
                                  value={editableData.outline?.detailedSummary}
                                  onChange={(e) => handleInputChange('outline', {...editableData.outline, detailedSummary: e.target.value})}
                                  placeholder="Enter detailed summary"
                        />
                    </div>
                    <div className='form--fieldLine'>
                        <label>Working summary:</label>
                    </div>
                    <div className='form--fieldLine'>
                        <textarea className='form-textarea'
                                  value={editableData.outline?.summaryWorkingExperience}
                                  onChange={(e) => handleInputChange('outline', {...editableData.outline, summaryWorkingExperience: e.target.value})}
                                  placeholder="Enter working summary"
                        />
                    </div>
                    <div className='form--fieldLine'>
                        <label>Education summary:</label>
                    </div>
                    <div className='form--fieldLine'>
                        <textarea className='form-textarea'
                                  value={editableData.outline?.summaryEducation}
                                  onChange={(e) => handleInputChange('outline', {...editableData.outline, summaryEducation: e.target.value})}
                                  placeholder="Enter education summary"
                        />
                    </div>
                    <div className='form--fieldLine'>
                        <label>Language summary:</label>
                    </div>
                    <div className='form--fieldLine'>
                        <textarea className='form-textarea'
                                  value={editableData.outline?.summaryLanguageSkills}
                                  onChange={(e) => handleInputChange('outline', {...editableData.outline, summaryLanguageSkills: e.target.value})}
                                  placeholder="Enter language summary"
                        />
                    </div>
                    <div className='form--fieldLine'>
                        <label>Skills summary:</label>
                    </div>
                    <div className='form--fieldLine'>

                    <textarea className='form-textarea'
                              value={editableData.outline?.summarySkills}
                              onChange={(e) => handleInputChange('outline', {...editableData.outline, summarySkills: e.target.value})}
                              placeholder="Enter skills summary"
                    />
                    </div>


                </fieldset>

            {/* Languages */}
                <fieldset className='form--fieldset'>
                    <div className='form--fieldLine'>
                        <label>Languages:</label>
                        <button className='form-sqButton'  onClick={() => addNewField('languages', { language: '', level: '' })}>+</button>
                    </div>
                {editableData.languages?.map((lang: any, index: number) => (
                    <div className='form--fieldInputLine' key={index}>
                        <input className='form-input' placeholder="Language" value={lang.language} onChange={(e) => {
                            const newLanguages = [...editableData.languages];
                            newLanguages[index].language = e.target.value;
                            handleInputChange('languages', newLanguages);
                        }} />
                        <input className='form-input' placeholder="Level" value={lang.level} onChange={(e) => {
                            const newLanguages = [...editableData.languages];
                            newLanguages[index].level = e.target.value;
                            handleInputChange('languages', newLanguages);
                        }} />
                        <button className='form-sqButton'  onClick={() => deleteField('languages', index)}>-</button>
                    </div>
                ))}
            </fieldset>

                <fieldset className='form--fieldset'>
                    <div className='form--fieldLine'>
                        <legend className='form--legend'>Education:</legend>
                        <button className='form-sqButton'  onClick={() => addNewField('education', {
                            degree: '',
                            educationName: '',
                            institutionName: '',
                            startDate: '',
                            endDate: '',
                            others: [],
                            location: {
                                country: '',
                                city: ''
                            },
                        })}>+</button>
                    </div>

                    {editableData.education?.map((edu: any, index: number) => (
                        <div key={index}>
                            <hr/>
                            <div className='form--fieldInputLine'>
                                <input style={{flexBasis: '40%'}} className='form-input' placeholder="Degree" value={edu.degree} onChange={(e) => {
                                    const newEducation = [...editableData.education];
                                    newEducation[index].degree = e.target.value;
                                    handleInputChange('education', newEducation);
                                }} />
                                <input style={{flexBasis: '70%'}} className='form-input' placeholder="Education Name" value={edu.educationName} onChange={(e) => {
                                    const newEducation = [...editableData.education];
                                    newEducation[index].educationName = e.target.value;
                                    handleInputChange('education', newEducation);
                                }} />
                                <button className='form-sqButton'  onClick={() => deleteField('education', index)}>-</button>
                            </div>
                            <div className='form--fieldInputLine'>
                                <input style={{flexBasis: '50%'}} className='form-input' placeholder="Institution Name" value={edu.institutionName} onChange={(e) => {
                                    const newEducation = [...editableData.education];
                                    newEducation[index].institutionName = e.target.value;
                                    handleInputChange('education', newEducation);
                                }} />
                                <input style={{flexBasis: '10%'}} className='form-input' placeholder="Start Date" value={edu.startDate} onChange={(e) => {
                                    const newEducation = [...editableData.education];
                                    newEducation[index].startDate = e.target.value;
                                    handleInputChange('education', newEducation);
                                }} />
                                <input style={{flexBasis: '10%'}} className='form-input' placeholder="End Date" value={edu.endDate} onChange={(e) => {
                                    const newEducation = [...editableData.education];
                                    newEducation[index].endDate = e.target.value;
                                    handleInputChange('education', newEducation);
                                }} />
                            </div>
                            <div className='form--fieldInputLine'>
                                <input className='form-input' placeholder="Country" value={edu.location.country} onChange={(e) => {
                                    const newEducation = [...editableData.education];
                                    newEducation[index].location.country = e.target.value;
                                    handleInputChange('education', newEducation);
                                }} />
                                <input className='form-input' placeholder="City" value={edu.location.city} onChange={(e) => {
                                    const newEducation = [...editableData.education];
                                    newEducation[index].location.city = e.target.value;
                                    handleInputChange('education', newEducation);
                                }} />
                            </div>

                        </div>
                    ))}
                </fieldset>

                <fieldset className='form--fieldset'>
                    <div className='form--fieldLine'>
                        <legend className='form--legend'>Certificates:</legend>
                        <button className='form-sqButton'  onClick={() => addNewField('certificates', {
                            date: '',
                            description: '',
                            institution: '',
                            name: '',
                            others: []
                        })}>+</button>
                    </div>

                    {editableData.certificates?.map((certificate: any, index: number) => (
                        <div key={index}>
                            <hr/>
                            <div className='form--fieldInputLine'>
                                <input className='form-input' style={{flexBasis: '50%'}}
                                       placeholder="Name"
                                       value={certificate.name}
                                       onChange={(e) => {
                                           const newCertificates = [...editableData.certificates];
                                           newCertificates[index].name = e.target.value;
                                           handleInputChange('certificates', newCertificates);
                                       }}
                                />
                                <input className='form-input' style={{flexBasis: '50%'}}
                                       placeholder="Date"
                                       value={certificate.date}
                                       onChange={(e) => {
                                           const newCertificates = [...editableData.certificates];
                                           newCertificates[index].date = e.target.value;
                                           handleInputChange('certificates', newCertificates);
                                       }}
                                />
                                <button className='form-sqButton'  onClick={() => deleteField('certificates', index)}>-</button>
                            </div>
                            <div className='form--fieldInputLine'>
                                <input className='form-input'  style={{flexBasis: '46%'}}
                                       placeholder="Description"
                                       value={certificate.description}
                                       onChange={(e) => {
                                           const newCertificates = [...editableData.certificates];
                                           newCertificates[index].description = e.target.value;
                                           handleInputChange('certificates', newCertificates);
                                       }}
                                />
                                <input className='form-input'
                                       placeholder="Institution"
                                       value={certificate.institution}
                                       onChange={(e) => {
                                           const newCertificates = [...editableData.certificates];
                                           newCertificates[index].institution = e.target.value;
                                           handleInputChange('certificates', newCertificates);
                                       }}
                                />
                            </div>

                        </div>
                    ))}
                </fieldset>

                <fieldset className='form--fieldset'>
                    <div className='form--fieldLine'>
                        <legend className='form--legend'>Soft Skills:</legend>
                        <button className='form-sqButton'  onClick={() => addNewField('softSkills', '')}>+</button>
                    </div>

                    {editableData.softSkills?.map((skill: string, index: number) => (
                        <div key={index} className='form--fieldInputLine'>
                            <input className='form-input' style={{flexBasis: '100%'}}
                                placeholder="Soft Skill"
                                value={skill}
                                onChange={(e) => {
                                    const newSkills = [...editableData.softSkills];
                                    newSkills[index] = e.target.value;
                                    handleInputChange('softSkills', newSkills);
                                }}
                            />
                            <button className='form-sqButton'  onClick={() => deleteField('softSkills', index)}>-</button>
                        </div>
                    ))}
                </fieldset>

                <fieldset className='form--fieldset'>
                    <div className='form--fieldLine'>
                        <legend className='form--legend'>Hard Skills:</legend>
                        <button className='form-sqButton'  onClick={() => addNewField('hardSkills', '')}>+</button>
                    </div>

                    {editableData.hardSkills?.map((skill: string, index: number) => (
                        <div key={index} className='form--fieldInputLine'>
                            <input className='form-input' style={{flexBasis: '100%'}}
                                placeholder="Hard Skill"
                                value={skill}
                                onChange={(e) => {
                                    const newSkills = [...editableData.hardSkills];
                                    newSkills[index] = e.target.value;
                                    handleInputChange('hardSkills', newSkills);
                                }}
                            />
                            <button className='form-sqButton'  onClick={() => deleteField('hardSkills', index)}>-</button>
                        </div>
                    ))}
                </fieldset>


                <fieldset className='form--fieldset'>
                    <div className='form--fieldLine'>
                        <legend className='form--legend'>Jobs:</legend>
                        <button className='form-sqButton'  onClick={() => addNewField('jobs', {
                            companyName: '',
                            position: '',
                            description: '',
                            startDate: '',
                            endDate: '',
                            location: {
                                country: '',
                                city: ''
                            },
                            others: []
                        })}>+</button>
                    </div>

                    {editableData.jobs?.map((job: any, index: number) => (
                        <div key={index}>
                            <hr/>
                            <div className='form--fieldInputLine'>
                                <input style={{fontWeight: 'bolder'}} className='form-input' placeholder="Company Name" value={job.companyName} onChange={(e) => {
                                    const newJobs = [...editableData.jobs];
                                    newJobs[index].companyName = e.target.value;
                                    handleInputChange('jobs', newJobs);

                                }} />
                                <input className='form-input' placeholder="Role" value={job.role} onChange={(e) => {
                                    const newJobs = [...editableData.jobs];
                                    newJobs[index].role = e.target.value;
                                    handleInputChange('jobs', newJobs);
                                }} />
                                <button className='form-sqButton'  onClick={() => deleteField('jobs', index)}>-</button>
                            </div>
                            <div className='form--fieldInputLine'>
                                <input className='form-input' style={{flexBasis: '100%'}} placeholder="Description" value={job.description} onChange={(e) => {
                                    const newJobs = [...editableData.jobs];
                                    newJobs[index].description = e.target.value;
                                    handleInputChange('jobs', newJobs);
                                }} />
                            </div>
                            <div className='form--fieldInputLine'>
                                <input className='form-input' placeholder="Start Date" value={job.startDate} onChange={(e) => {
                                    const newJobs = [...editableData.jobs];
                                    newJobs[index].startDate = e.target.value;
                                    handleInputChange('jobs', newJobs);
                                }} />
                                <input className='form-input' placeholder="End Date" value={job.endDate} onChange={(e) => {
                                    const newJobs = [...editableData.jobs];
                                    newJobs[index].endDate = e.target.value;
                                    handleInputChange('jobs', newJobs);
                                }} />
                            </div>
                            <div className='form--fieldInputLine'>
                                <input className='form-input' placeholder="Country" value={job.location.country} onChange={(e) => {
                                    const newJobs = [...editableData.jobs];
                                    newJobs[index].location.country = e.target.value;
                                    handleInputChange('jobs', newJobs);
                                }} />
                                <input className='form-input' placeholder="City" value={job.location.city} onChange={(e) => {
                                    const newJobs = [...editableData.jobs];
                                    newJobs[index].location.city = e.target.value;
                                    handleInputChange('jobs', newJobs);
                                }} />
                            </div>
                        </div>
                    ))}
                </fieldset>





                <fieldset className='form--fieldset'>
                    <legend className='form--legend'>Interests:</legend>
                    <button className='form-sqButton'  onClick={() => addNewField('interests', '')}>+</button>
                    {editableData.interests?.map((interest: string, index: number) => (
                        <div key={index} className='form--fieldInputLine'>
                            <input className='form-input' style={{flexBasis: '100%'}}
                                placeholder="Interest"
                                value={interest}
                                onChange={(e) => {
                                    const newInterests = [...editableData.interests];
                                    newInterests[index] = e.target.value;
                                    handleInputChange('interests', newInterests);
                                }}
                            />
                            <button className='form-sqButton'  onClick={() => deleteField('interests', index)}>-</button>
                        </div>
                    ))}
                </fieldset>


                <fieldset className='form--fieldset'>
                    <div className='form--fieldLine'>
                        <label>Others:</label>
                        <button className='form-button' onClick={() => addNewCustomField('others')}>Add Custom Field</button>
                    </div>
                {editableData.others?.map((other: any, index: number) => (
                    <div className='form--fieldInputLine' key={index}>
                        <input className='form-input'
                            placeholder="Name"
                            value={other.name}
                            onChange={(e) => {
                                const newOthers = [...editableData.others];
                                newOthers[index].name = e.target.value;
                                handleInputChange('others', newOthers);
                            }}
                        />
                        <input className='form-input'
                            placeholder="Data"
                            value={other.data}
                            onChange={(e) => {
                                const newOthers = [...editableData.others];
                                newOthers[index].data = e.target.value;
                                handleInputChange('others', newOthers);
                            }}
                        />
                        <button className='form-sqButton'  onClick={() => deleteField('others', index)}>-</button>
                    </div>
                ))}
            </fieldset>
            </div>
            <br/><br/><br/>
        </div>
    );
};

export default EditPersonCard;