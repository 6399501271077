import React from "react";

function stringToColor(string) {
    let hash = 0;
    for (let i = 0; i < string.length; i++) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';
    for (let i = 0; i < 3; i++) {
        const value = (hash >> (i * 8)) & 0xff;
        color += `00${value.toString(16)}`.slice(-2);
    }

    return color;
}

function hexToRGB(hex) {
    let r = parseInt(hex.slice(1, 3), 16),
        g = parseInt(hex.slice(3, 5), 16),
        b = parseInt(hex.slice(5, 7), 16);
    return { r, g, b };
}

function luminance({ r, g, b }) {
    // Formula to calculate luminance
    return 0.299 * r + 0.587 * g + 0.114 * b;
}

function adjustColor(color, amount) {
    return {
        r: Math.min(Math.max(color.r + amount, 0), 255),
        g: Math.min(Math.max(color.g + amount, 0), 255),
        b: Math.min(Math.max(color.b + amount, 0), 255)
    };
}

function RGBToHex(rgb) {
    return `#${((1 << 24) + (rgb.r << 16) + (rgb.g << 8) + rgb.b).toString(16).slice(1)}`;
}

const StringAvatar = ({name=''}) => {
    const bgColor = stringToColor(name);
    const rgbColor = hexToRGB(bgColor);
    const lum = luminance(rgbColor);

    // Adjust the color based on luminance
    const adjustmentAmount = 70; // 20% of 255
    const adjustedColor = lum > 128 ? adjustColor(rgbColor, -adjustmentAmount) : adjustColor(rgbColor, adjustmentAmount);
    const adjustedHex = RGBToHex(adjustedColor);

    const nameParts = name.split(' ');
    let initials = nameParts[0][0];
    if (nameParts[1]) {
        initials += nameParts[1][0];
    }

    return (
        <div
            className="h-10 w-10 rounded-full flex-shrink-0 flex items-center justify-center"
             style={{ backgroundColor: bgColor,
                 // color: adjustedHex ,
                 color: 'white',
                 // border: `1px solid ${adjustedHex}`,
                 border: `1px solid ${bgColor}`
                 }}>
            {initials}
        </div>
    );
}

export default StringAvatar;