import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../app/reducers";
import {behindAPI} from "../../../app";
import dayjs from "dayjs";
import callOut from "../../../shared/assets/img/call-out-r.png";
import callIn from "../../../shared/assets/img/call-in-b.png";
import './personCardLogs.scss'
import {setModalDataReducer, setModalTemplateReducer} from "../../../app/reducers/appReducer";

type LogItem = {
    _id: string;
    id: string;
    direction: string;
    status: string;
    guest: {
        phone_number: string;
        name: string;
        user_id: string;
        individual_id: string;
    };
    from: string;
    to: string;
    timestamp: string;
    chat: {
        title?: string;
    };
};


const PersonCardLogs = () => {

    const person = useSelector((state: RootState) => state.contact.currentContactId)
    const [logs, setLogs] = useState<LogItem[]>([]);
    const dispatch = useDispatch()

    useEffect(() => {
        (async () => {
            await getTranscripts()
        })();

        return () => {
            // this now gets called when the component unmounts
        };
    }, [person]);



    const getTranscripts = async () => {
        console.log(person)
        console.log(person.user_id)
        try {
            const res = await behindAPI.SipTranscriptsGetByIndividualId(person)
            if (res.success === true) {
                console.log(res)
                setLogs(res.data)
            }
        } catch (e) {
            console.log(e)
        }

    }

    const [callData, setCallData] = useState({})

    const onLogClick = async(value:string) => {
        const res = await behindAPI.SipTranscriptGet(value)
        console.log(res)
        if (res?.success === true) {
            setCallData(res?.data)
            dispatch(setModalDataReducer(res?.data))
            dispatch(setModalTemplateReducer('callLog'))
        }

    }

    return (
        <>
            {logs && logs?.length > 0 ?
            <div className="companyCard-line">
                    <label className='form-label'>Logs</label><br/>
                    <div className='personCardLogs'>
                    {logs.map((log) => (
                        <div key={log._id} className='personCardLogs__line' onClick={() => onLogClick(log._id)}>
                            <>
                                {log.direction === 'OUT' ?
                                    <>
                                        <img style={{zoom: '0.7', opacity: 1}} className='directionImage' src={callOut} />
                                    </>
                                    :
                                    <>
                                        <img style={{zoom: '0.7', opacity: 1}} className='directionImage' src={callIn} />
                                    </>
                                }
                            </>
                            <span style={{fontSize: 13}}>{dayjs(log.timestamp).format('DD MMM YY HH:mm')}</span>
                            &nbsp;
                            {/*<div><strong>Status:</strong> {log.status}</div>*/}
                            {/*<div><strong>From:</strong> {log.from}</div>*/}
                            {/*<div><strong>To:</strong> {log.to} ({log.guest.name})</div>*/}
                            {log.chat.title || <span className='personCardLogs--unavailable'>Logs unavailable</span>}
                        </div>
                    ))}
                </div>
                    <div className="companyCard-border"/>
            </div>
                :
                <div className={'w-full text-center p-4'}>
                    <i>No logs yet...</i>
                </div>
            }
        </>

    );
};

export default PersonCardLogs;