import React, { useEffect, useState } from 'react';
import {useDispatch, useSelector} from 'react-redux';
import { RootState } from '../../app/reducers';
import {ArrowUturnLeftIcon} from "@heroicons/react/24/outline";
import {setJobDescriptionMode, setProjectSelection} from "../../app/reducers/raet/raetReducer";
import {behindAPI} from "../../app";
import PWButton from "../../shared/ui/btn/PhonewiseButton";
import {setSipContactDispatch} from "../../app/reducers/sipReducer";
import {PhoneIcon} from "@heroicons/react/24/solid";

interface Requirement {
    data: string;
    question: string;
}

interface JobDescription {
    Name: string;
    Role: string;
    Job: string;
    Culture: string;
    MainRequirements: Requirement[];
    Responsibilities: string[];
    RequiredHardSkills: Requirement[];
    RecommendedHardSkills: Requirement[];
    SoftSkills: Requirement[];
    CodingLanguages: Requirement[];
    Frameworks: Requirement[];
    OperatingSystems: Requirement[];
    CulturalSkills: Requirement[];
}

const JobDescriptionEdit: React.FC = () => {
    const [jobDetails, setJobDetails] = useState<JobDescription | null>(null);
    const dispatch = useDispatch();

    const project = useSelector((state: RootState) => state.raet.projectSelection);

    useEffect(() => {
        if (project && project.jd) {
            setJobDetails(project.jd);
        }
    }, [project]);

    const handleChange = (field: keyof JobDescription, value: string | string[]) => {
        if (!jobDetails) return;
        setJobDetails({ ...jobDetails, [field]: value });
    };

    if (!jobDetails) {
        return <div>Loading...</div>;
    }

    const onUpdateJobDescription = async () => {
        try {
            const update = await behindAPI.RaetJdUpdate(project?._id, jobDetails);
            if (update.success) {
                const res = await behindAPI.RaetProjectGet(project?._id);
                if (res?.success) {
                    dispatch(setProjectSelection(res?.data))
                    dispatch(setJobDescriptionMode('overview'))
                }
            }
        } catch (error) {
            console.error(error);
        }
    }

    return (
        <div className="p-6 space-y-6">
            <div className='flex items-center justify-between'>
                <div className='flex items-center'>
                    <div className='bg-gray-100 hover:bg-gray-200 rounded-xl p-2 mr-4 cursor-pointer max-w-9 max-h-9'
                         onClick={() => dispatch(setJobDescriptionMode('overview'))}
                    >
                        <ArrowUturnLeftIcon style={{width: 20}}/>
                    </div>
                    <div className='text-xl font-semibold'>
                        Edit job description
                    </div>
                </div>
                <div>
                    <PWButton
                        onClick={()=>onUpdateJobDescription()}
                    >
                        Save
                    </PWButton>
                </div>
            </div>
            <div>
                <label className="block text-sm font-medium text-gray-700">Name</label>
                <input
                    type="text"
                    value={jobDetails.Name}
                    onChange={(e) => handleChange('Name', e.target.value)}
                    className="mt-1 block w-full px-3 py-2 bg-gray-100 border-none rounded-lg shadow-sm focus:outline-none focus:ring-blue-500 text-base"
                />
            </div>

            <div>
                <label className="block text-sm font-medium text-gray-700">Role</label>
                <textarea
                    value={jobDetails.Role}
                    onChange={(e) => handleChange('Role', e.target.value)}
                    className="mt-1 block w-full px-3 py-2 bg-gray-100 border-none rounded-lg shadow-sm focus:outline-none focus:ring-blue-500 text-base"
                    rows={4}
                />
            </div>

            <div>
                <label className="block text-sm font-medium text-gray-700">Job</label>
                <textarea
                    value={jobDetails.Job}
                    onChange={(e) => handleChange('Job', e.target.value)}
                    className="mt-1 block w-full px-3 py-2 bg-gray-100 border-none rounded-lg shadow-sm focus:outline-none focus:ring-blue-500 text-base"
                    rows={4}
                />
            </div>

            <div>
                <label className="block text-sm font-medium text-gray-700">Culture</label>
                <textarea
                    value={jobDetails.Culture}
                    onChange={(e) => handleChange('Culture', e.target.value)}
                    className="mt-1 block w-full px-3 py-2 bg-gray-100 border-none rounded-lg shadow-sm focus:outline-none focus:ring-blue-500 text-base"
                    rows={4}
                />
            </div>

            {['MainRequirements', 'Responsibilities', 'RequiredHardSkills', 'RecommendedHardSkills', 'SoftSkills', 'CodingLanguages', 'Frameworks', 'OperatingSystems', 'CulturalSkills'].map((field) => (
                <div key={field}>
                    <label className="block text-sm font-medium text-gray-700">{field.replace(/([A-Z])/g, ' $1')}</label>
                    <textarea
                        //@ts-ignore
                        value={Array.isArray(jobDetails[field as keyof JobDescription])
                            ? (jobDetails[field as keyof JobDescription] as (Requirement | string)[]).map(item => typeof item === 'string' ? item : item.data).join('\n')
                            : jobDetails[field as keyof JobDescription]}
                        onChange={(e) => handleChange(field as keyof JobDescription, e.target.value.split('\n'))}
                        className="mt-1 block w-full px-3 py-2 bg-gray-100 border-none rounded-lg shadow-sm focus:outline-none focus:ring-blue-500 text-base"
                        rows={4}
                    />
                </div>
            ))}
            {/*{JSON.stringify(jobDetails)}*/}
        </div>
    );
};

export default JobDescriptionEdit;
