import React from 'react';

const SystemLogin = () => {

    const access_token = localStorage.getItem('access_token')

    return (
        <>
            {access_token!==null && access_token!==undefined && access_token!=='' && access_token.length>0 ?
                <>
                </>
                :
                <div className={'flex justify-center bg-pink-100 text-base p-1 text-pink-800'}>
                    <div className={'flex justify-center'}>
                        <p>You are not logged in</p>
                        <a  className={'ml-2 underline'}
                            href={'/auth.php'}>Login</a>
                    </div>
                </div>
            }
        </>
    );
};

export default SystemLogin;