import React, {useState} from 'react';
import {PlusIcon} from "@heroicons/react/24/outline";
import {useDispatch, useSelector} from "react-redux";
import {setMastogramInstanceData, setMastogramMainTableMode} from "../../app/reducers/mastogram/mastogramReducer";
import {RootState} from "../../app/reducers";
import PWButton from "../../shared/ui/btn/PhonewiseButton";

const MastogramChannels = () => {
    const dispatch = useDispatch();
    const instances = useSelector((state: RootState) => state.mastogram.instancesData);
    const handleRowClick = (instance: any) => {
        dispatch(setMastogramMainTableMode('edit'));
        dispatch(setMastogramInstanceData(instance));
    }
    const onCreate = () => {
        dispatch(setMastogramMainTableMode('create'));
    }
    return (
        <>
            {instances?.length === 0 ?
                <div className={'flex flex-col items-center justify-center py-20 px-4'}>
                    <div className={'text-black/80 text-base'}><i>You have no any channels yet</i></div>
                    <div className={'mt-10'}>
                        <PWButton
                            onClick={() => onCreate()}
                        >
                            Create
                        </PWButton>
                    </div>
                </div>

                :
                <div className={'pb-10'}>
                    <div style={{display: "flex", justifyContent: "flex-end", paddingTop: 20, paddingBottom: 10, width: '100%'}}>
                        <div className='bg-gray-100 hover:bg-gray-200 rounded-xl p-2 cursor-pointer mr-6 max-h-9'
                             onClick={() => dispatch(setMastogramMainTableMode('create'))}
                        >
                            <PlusIcon style={{width: 20}}/>
                        </div>
                    </div>
                    <div className="w-full h-full flex flex-col">
                        <div className="overflow-x-auto">
                            <table className="min-w-full bg-white">
                                <thead>
                                <tr>
                                    <th className="px-4 py-2 w-2/12 text-left">Enabled</th>
                                    <th className="px-4 py-2 w-8/12 text-left">Channels</th>
                                    <th className="px-4 py-2 w-2/12 text-left">Redirect/Receive</th>
                                </tr>
                                </thead>
                                <tbody>
                                {/*@ts-ignore*/}
                                {instances?.map((instance) => (
                                    <tr
                                        key={instance.tg_uuid}
                                        onClick={() => handleRowClick(instance)}
                                        className="hover:bg-gray-100 cursor-pointer"
                                    >
                                        <td className={'px-4 w-1/10 max-w-1/10'}>
                                            <label className="inline-flex items-center cursor-pointer">
                                                <input
                                                    type="checkbox"
                                                    checked={instance.tg_enabled}
                                                    // onChange={() => setInstanceData({
                                                    //     ...instanceData,
                                                    //     tg_enabled: !instanceData.tg_enabled // Toggle tg_enabled on change
                                                    // })}
                                                    className="sr-only peer"
                                                />
                                                <div
                                                    className="relative w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-violet-300 dark:peer-focus:ring-violet-800 dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-violet-600"
                                                ></div>
                                            </label>
                                        </td>
                                        <td className="px-4 py-2 w-3/10 max-w-3/10">
                                            {instance.tg_name ? instance.tg_name : instance?.tg_uuid}
                                        </td>
                                        <td className="px-4 py-2 w-3/5 max-w-3/5">
                                            <div className={'flex justify-center'}>

                                                <svg  viewBox="0 0 127 122" className="size-6" xmlns="http://www.w3.org/2000/svg">
                                                    <path className="st0"
                                                          d="m86 24.9c12.9 12.9 12.9 33.8 0 46.7m-46.7-0.1c-12.9-12.9-12.9-33.8 0-46.7m-15.5 62.3c-21.5-21.5-21.5-56.3 0-77.8m77.7 0c21.5 21.5 21.5 56.3 0 77.8m-38.8-27.9c6.1 0 11-4.9 11-11s-4.9-11-11-11-11 4.9-11 11 4.9 11 11 11zv49.5"
                                                          fill="none" stroke={instance?.tg_forward ? "rgb(91, 33, 182)" : "rgba(0,0,0,.2)" }  stroke-width="12" stroke-lineca="round" stroke-linejoin="round" stroke-miterlimit="133.3333"
                                                    />
                                                </svg>
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"  className="size-6">
                                                    <path d="M16.19 2H7.81C4.17 2 2 4.17 2 7.81V16.18C2 19.83 4.17 22 7.81 22H16.18C19.82 22 21.99 19.83 21.99 16.19V7.81C22 4.17 19.83 2 16.19 2ZM8.47 10.98C8.76 10.69 9.24 10.69 9.53 10.98L11.25 12.7V6.51C11.25 6.1 11.59 5.76 12 5.76C12.41 5.76 12.75 6.1 12.75 6.51V12.7L14.47 10.98C14.76 10.69 15.24 10.69 15.53 10.98C15.82 11.27 15.82 11.75 15.53 12.04L12.53 15.04C12.46 15.11 12.38 15.16 12.29 15.2C12.2 15.24 12.1 15.26 12 15.26C11.9 15.26 11.81 15.24 11.71 15.2C11.62 15.16 11.54 15.11 11.47 15.04L8.47 12.04C8.18 11.75 8.18 11.28 8.47 10.98ZM18.24 17.22C16.23 17.89 14.12 18.23 12 18.23C9.88 18.23 7.77 17.89 5.76 17.22C5.37 17.09 5.16 16.66 5.29 16.27C5.42 15.88 5.84 15.66 6.24 15.8C9.96 17.04 14.05 17.04 17.77 15.8C18.16 15.67 18.59 15.88 18.72 16.27C18.84 16.67 18.63 17.09 18.24 17.22Z"
                                                          fill={instance?.tg_post ? "rgb(91, 33, 182)" : "rgba(0,0,0,.2)" }/>

                                                </svg>


                                            </div>
                                        </td>

                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>
                    </div>

                </div>
            }

        </>

    );
};

export default MastogramChannels;