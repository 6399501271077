import React from 'react';
import './loader.scss'

interface LoaderProps {
    color?: 'light' | 'dark';
}
const Loader:React.FC<LoaderProps> = ({color= 'dark'}) => {
    console.log('Loader color: ', color)

    return (
        <div className={`loader-container ${color === 'light' && 'loader-container--light'}`}/>
    );
};

export default Loader;