import React, {useEffect, useState} from 'react';
import pwLogo from '../../shared/assets/img/mastogram-logo-b.png'
// @ts-ignore
import {
    UserIcon,
    CalendarIcon,
    HeartIcon,
    UsersIcon,
    FireIcon, NewspaperIcon, Cog8ToothIcon, DocumentTextIcon, ArrowUturnLeftIcon, PlusIcon, XMarkIcon,
} from '@heroicons/react/24/outline';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    IconButton,
    Button,
} from '@mui/material';
import LogList from "../sales/mailList/logList";
import {useDispatch, useSelector} from "react-redux";
import {
    setJobDescriptionMode,
    setProjectSelection,
    setTabSelection
} from "../../app/reducers/raet/raetReducer";
import {behindAPI} from "../../app";
import CreateProjectDialog from "../../features/raet/raetCreateProjectDialog";
import {currentContactDispatcher, setContactIds, setCurrentContactId} from "../../app/reducers/currentContact";
import {RootState} from "../../app/reducers";
import PhonewiseContacts from "../../widgets/raet/PhonewiseContacts";
import Loader from "../../shared/ui/loader";
import MastogramChannels from "../../widgets/mastogram/mastogramChannels";
import MastogramMastodonCreateEdit from "../../widgets/mastogram/mastogramMastodonCreateEdit";
import {
    setMastogramInstancesData,
    setMastogramMainTableMode, setMastogramSelectedGroup
} from "../../app/reducers/mastogram/mastogramReducer";
import MastogramInstances from "../../widgets/mastogram/mastogramInstances";
import MastogramTelegramCreateEdit from "../../widgets/mastogram/mastogramTelegramCreateEdit";


// @ts-ignore
const NavigationBar = () => {
    const dispatch = useDispatch();
    const selectedTab = useSelector((state: RootState) => state.raet.tabSelection);
    return (
        <div className="flex space-x-6 bg-zinc-100 p-1 rounded-lg mt-4 mx-6 mr-11">
            {/* Job Description Tab */}
            <div
                className={`px-7 py-1 rounded-lg font-medium cursor-pointer transition-all duration-300 ${
                    selectedTab === 'projects' ? 'bg-white text-black shadow-sm' : 'text-gray-700'
                }`}
                onClick={() => dispatch(setTabSelection('projects'))}
            >
                Job description
            </div>
            <div className="flex items-center py-2">
                <div className="h-full border-r border-gray-300"></div>
            </div>
            <div
                className={`px-7 py-1 rounded-lg font-medium cursor-pointer transition-all duration-300 ${
                    selectedTab === 'resume' ? 'bg-white text-black shadow-sm' : 'text-gray-700'
                }`}
                onClick={() => dispatch(setTabSelection('resume'))}
            >
                Resume
            </div>
            {/* Divider */}
            <div className="flex items-center py-2">
                <div className="h-full border-r border-gray-300"></div>
            </div>
            {/* Calls Tab */}
            <div
                className={`px-7 py-1 rounded-lg  font-medium cursor-pointer transition-all duration-300 ${
                    selectedTab === 'calls' ? 'bg-white text-black shadow-sm' : 'text-gray-700'
                }`}
                onClick={() => dispatch(setTabSelection('calls'))}
            >
                Calls
            </div>

            <div
                className={`px-7 py-1 rounded-lg  font-medium cursor-pointer transition-all duration-300 ${
                    selectedTab === 'settings' ? 'bg-white text-black shadow-sm' : 'text-gray-700'
                }`}

            >
                Settings
            </div>
        </div>
    );
};

const people = [
    {
        name: 'Leo Polovets',
        title: 'General Partner, Susa Ventures',
        tags: ['Saas', 'Analytics', '+95'],
        location: 'US / Canada',
    },
    {
        name: 'Thanos Papadimitriou',
        title: 'Partner, Charge Ventures',
        tags: ['Transportation', '+49'],
        location: 'US / Canada',
    },
    {
        name: 'Susan Lyne',
        title: 'Managing Partner, BBG Ventures',
        tags: ['Consumer Electronics', '+70'],
        location: 'US / Canada',
    },
    {
        name: 'Ashly WhiteHurst',
        title: 'Managing Partner, Launch',
        tags: ['FinTech', 'Saas', '+98'],
        location: 'US / Canada',
    },
    {
        name: 'Kanyi Maqubela',
        title: 'Managing Partner, Kindred Ventures',
        tags: ['Web3', '+64'],
        location: 'Europe',
    },
    {
        name: 'Alex Hartz',
        title: 'Partner, SciFi VC',
        tags: ['Artificial Intelligence', '+44'],
        location: 'US / Canada',
    },
    {
        name: 'Jesse Walden',
        title: 'Managing Partner, Variant',
        tags: ['Web3', '+10'],
        location: 'US / Canada',
    },
    {
        name: 'David Mort',
        title: 'General Partner, Propel VC',
        tags: ['FinTech', '+33'],
        location: 'US / Canada',
    },
    {
        name: 'Ryan Shuler',
        title: 'Managing Partner, Legendary',
        tags: ['E-Commerce', '+29'],
        location: 'Europe',
    },
];

// List item component
// @ts-ignore
const ListItem = ({ person }) => (
    <div className="flex items-center space-x-4 py-4 border-b border-gray-200">
        <div className="w-2/5 flex items-center space-x-4">
            <div className="h-10 w-10 rounded-full bg-gray-300"></div>
            <div>
                <div className="font-medium text-gray-900">{person.name}</div>
                <div className="text-sm text-gray-500">{person.title}</div>
            </div>
        </div>
        <div className="w-2/5 flex space-x-2">
            {person.tags.map((tag:any, index:string) => (
                <span key={index} className="px-2 py-1 bg-green-100 text-green-700 text-xs rounded-full">
          {tag}
        </span>
            ))}
        </div>
        <div className="w-1/5">
      <span className="px-2 py-1 bg-green-100 text-green-700 text-xs rounded-full">
        {person.location}
      </span>
        </div>
    </div>
);

const List = () => (
    <div className="mx-auto mt-10 bg-white">
        {/* Headers */}
        <div className="flex items-center space-x-4 py-2 font-semibold text-gray-700 border-b border-gray-300">
            <div className="w-2/5">Candidate</div>
            <div className="w-2/5">Skills</div>
            <div className="w-1/5">Rank</div>
        </div>

        {/* List items */}
        {people.map((person, index) => (
            <ListItem key={index} person={person} />
        ))}
    </div>
);


const MastogramMain = () => {
    const selectedTab = useSelector((state: RootState) => state.raet.tabSelection);
    const dispatch = useDispatch();
    const [openModal, setOpenModal] = useState(false);
    const [projects, setProjects] = useState([]);
    const selectedProject = useSelector((state: RootState) => state.raet.projectSelection);
    const mastogramMode = useSelector((state: RootState) => state.mastogram.mainTableMode);
    const mastogramGroup = useSelector((state: RootState) => state.mastogram.selectedGroup);
    const handleOpenModal = () => setOpenModal(true);
    const handleCloseModal = () => setOpenModal(false);

    const onProjectSelect = async (project: any) => {
        dispatch(setProjectSelection(project))
        try {
            let res;
            if (project === 'mastodon') {
                res = await behindAPI.MastogramMastodonGet();
                dispatch(setMastogramSelectedGroup('mastodon'))
            } else if (project === 'telegram') {
                res = await behindAPI.MastogramTelegramGet();
                dispatch(setMastogramSelectedGroup('telegram'))
            }
            console.log('Project:', res);
            if (res.success) {
                dispatch(setMastogramMainTableMode('overview'));
                dispatch(setMastogramInstancesData(res?.data))
            }
        } catch (error) {
            console.error('Failed to fetch projects:', error);
        }
    }

    const fetchProjects = async () => {
        try {
            const res = await behindAPI.RaetProjectsGet();
            if (res.success) {
                console.log('Projects:', res?.data);
                setProjects(res?.data)
            }
        } catch (error) {
            console.error('Failed to fetch projects:', error);
        }
    }

    useEffect(() => {
        (async () => {
            try {
                let res;
                res = await behindAPI.MastogramMastodonGet();
                dispatch(setMastogramSelectedGroup('mastodon'))
                console.log('Project:', res);
                if (res.success) {
                    dispatch(setMastogramMainTableMode('overview'));
                    dispatch(setMastogramInstancesData(res?.data))
                }
            } catch (error) {
                console.error('Failed to fetch projects:', error);
            }
        })();
        return () => {
            // this now gets called when the component unmounts
        };
    }, []);



    return (
        <div className="flex bg-slate-50 min-w-full max-h-screen">
            {/*{JSON.stringify(projects)}*/}
            <div className="w-72 max-w-72 h-screen py-4 ml-10 min-w-72 shrink-0">
                {/* Logo */}
                <div className="mb-6">
                    <div className="flex items-center justify-start">
                        <img src={pwLogo} alt="logo"
                             style={{maxWidth: '160px'}}
                        />
                    </div>
                </div>


                {/* Followings */}
                <div className="mt-8">
                    <div className='flex items-center justify-between mb-4'>
                        <h3 className="text-gray-600 font-medium">Projects</h3>
                    </div>
                    <div
                        className={`${mastogramGroup === 'mastodon' && 'bg-gray-100'} flex cursor-pointer px-4 py-2 rounded-lg hover:bg-gray-100 mb-2`}
                        onClick={() => (onProjectSelect('mastodon'))}>
                        <div
                            style={{ position: 'relative',
                            }}
                            className={` h-8 w-8 rounded-lg flex items-center justify-center`}
                        >
                            <svg fill="#000000" width="32px" height="32px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <path d="M21.327 8.566c0-4.339-2.843-5.61-2.843-5.61-1.433-.658-3.894-.935-6.451-.956h-.063c-2.557.021-5.016.298-6.45.956 0 0-2.843 1.272-2.843 5.61 0 .993-.019 2.181.012 3.441.103 4.243.778 8.425 4.701 9.463 1.809.479 3.362.579 4.612.51 2.268-.126 3.541-.809 3.541-.809l-.075-1.646s-1.621.511-3.441.449c-1.804-.062-3.707-.194-3.999-2.409a4.523 4.523 0 0 1-.04-.621s1.77.433 4.014.536c1.372.063 2.658-.08 3.965-.236 2.506-.299 4.688-1.843 4.962-3.254.434-2.223.398-5.424.398-5.424zm-3.353 5.59h-2.081V9.057c0-1.075-.452-1.62-1.357-1.62-1 0-1.501.647-1.501 1.927v2.791h-2.069V9.364c0-1.28-.501-1.927-1.502-1.927-.905 0-1.357.546-1.357 1.62v5.099H6.026V8.903c0-1.074.273-1.927.823-2.558.566-.631 1.307-.955 2.228-.955 1.065 0 1.872.409 2.405 1.228l.518.869.519-.869c.533-.819 1.34-1.228 2.405-1.228.92 0 1.662.324 2.228.955.549.631.822 1.484.822 2.558v5.253z"/>
                            </svg>
                        </div>
                        <div  className="flex-1 h-8 flex items-center justify-center">  Mastodon instances</div>

                    </div>
                    <div
                        className={`${mastogramGroup === 'telegram' && 'bg-gray-100'} flex cursor-pointer px-4 py-2 rounded-lg hover:bg-gray-100 mb-2`}
                        onClick={() => (onProjectSelect('telegram'))}
                    >
                        <div
                            style={{ position: 'relative',
                            }}
                            className={` h-8 w-8 rounded-lg flex items-center justify-center`}
                        >
                            <svg fill="#000000" width="32px" height="32px" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
                                <path d="M22.122 10.040c0.006-0 0.014-0 0.022-0 0.209 0 0.403 0.065 0.562 0.177l-0.003-0.002c0.116 0.101 0.194 0.243 0.213 0.403l0 0.003c0.020 0.122 0.031 0.262 0.031 0.405 0 0.065-0.002 0.129-0.007 0.193l0-0.009c-0.225 2.369-1.201 8.114-1.697 10.766-0.21 1.123-0.623 1.499-1.023 1.535-0.869 0.081-1.529-0.574-2.371-1.126-1.318-0.865-2.063-1.403-3.342-2.246-1.479-0.973-0.52-1.51 0.322-2.384 0.221-0.23 4.052-3.715 4.127-4.031 0.004-0.019 0.006-0.040 0.006-0.062 0-0.078-0.029-0.149-0.076-0.203l0 0c-0.052-0.034-0.117-0.053-0.185-0.053-0.045 0-0.088 0.009-0.128 0.024l0.002-0.001q-0.198 0.045-6.316 4.174c-0.445 0.351-1.007 0.573-1.619 0.599l-0.006 0c-0.867-0.105-1.654-0.298-2.401-0.573l0.074 0.024c-0.938-0.306-1.683-0.467-1.619-0.985q0.051-0.404 1.114-0.827 6.548-2.853 8.733-3.761c1.607-0.853 3.47-1.555 5.429-2.010l0.157-0.031zM15.93 1.025c-8.302 0.020-15.025 6.755-15.025 15.060 0 8.317 6.742 15.060 15.060 15.060s15.060-6.742 15.060-15.060c0-8.305-6.723-15.040-15.023-15.060h-0.002q-0.035-0-0.070 0z"></path>
                            </svg>
                        </div>
                        <div  className="flex-1 h-8 flex items-center justify-center">  Telegram channels</div>
                    </div>
                    <div className="space-y-4">
                        {projects?.map((project, index) => (
                            // @ts-ignore
                            <div key={index} className={`flex items-center px-4 py-2 space-x-2 cursor-pointer rounded-lg hover:bg-gray-100 ${project?._id === selectedProject?._id && 'bg-gray-100'}`}
                                 onClick={() => (onProjectSelect(project))}
                            >
                                <div style={{ position: 'relative' }}>
                                    <div
                                        style={{ position: 'relative',
                                            // @ts-ignore
                                            backgroundImage: `url(${project?.image?.thumbnail})`,
                                            backgroundSize: 'cover',
                                            backgroundPosition: 'center',
                                        }}
                                        className={`bg-gray-200 h-8 w-8 rounded-lg flex items-center justify-center`}
                                    >
                                    </div>
                                    <div
                                        style={{ position: 'absolute', top: '-2px', right: '-2px' }}
                                        className={`h-2 w-2 rounded-full`}
                                    />
                                </div>
                                {/*// @ts-ignore*/}
                                <span className="flex-1">{project?.status === 'pending' && <Spinner/>}{project?.name}</span>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <div className="w-10/12 flex flex-col max-v-screen">
                <div className="flex-1 overflow-y-scroll">
                    <div className="flex-1 bg-white rounded-3xl m-7 mt-4">
                        {mastogramGroup === 'mastodon' &&
                            <>
                                {(mastogramMode === 'create' || mastogramMode === 'edit')  ? <MastogramMastodonCreateEdit/> : <MastogramInstances/>}
                            </>
                        }
                        {mastogramGroup === 'telegram' &&
                        <>
                            {(mastogramMode === 'create' || mastogramMode === 'edit')  ? <MastogramTelegramCreateEdit/> : <MastogramChannels/>}
                        </>
                        }


                    </div>
                </div>

            </div>
        </div>
    );
};

const Spinner = () => {
    return (
        <div role="status" className={'inline-block mr-2'}>
            <svg aria-hidden="true"
                 className="inline w-6 h-6 text-gray-200 animate-spin dark:text-gray-600 fill-violet-600"
                 viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                    fill="currentColor"/>
                <path
                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                    fill="currentFill"/>
            </svg>
            <span className="sr-only">Loading...</span>
        </div>
    );
}

export default MastogramMain;